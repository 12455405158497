import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import Select from 'react-select';
import { EditGroupProps, EditGroupState } from '../../../interface/newMenu';
import { secretKey } from '../../../../secret';
import { editGroup, getGroup, logoutUser, menusList, storesListForMultiSelect } from '../../../../redux';
import { OrderingModes } from '../../../../redux/actions/menuAction';
import { Button, Modal } from 'react-bootstrap';

class EditGroup extends Component<EditGroupProps, EditGroupState> {
    orderModes: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.orderModes = [
            { value: 'online', label: 'Online' },
            { value: 'mobile', label: 'Mobile' },
            { value: 'callcenter', label: 'Call Center' }
        ];
        this.state = {
            groupname: "",
            group_description:"",
            menu: "",
            image: "",
            priority: "",
            poscode: "",
            erpid: "",
            is_lsm: '0',
            storesJson: [],
            mode: [],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.menusList();
        // let id = this.props.group_id;
        // this.props.getGroup(id);
        this.props.storesList();
        this.props.OrderingModes()
        document.title = "Groups"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        console.log("nextProps",nextProps.groupData)
        this.setState({
            groupname: nextProps.groupData.group_name,
            group_description: nextProps.groupData.group_description
        })
        
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    fileSelectedHandler = (e: any) => {
        this.setState({ image: e.target.files })
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    isGroupReady = () => {
        const { groupname } = this.state;
        return (groupname !== "");
         
    }
    // shouldComponentUpdate(nextProps:any) {
    //     return true;
    // }
    handleSubmit = (event: any) => {
        let { groupname,group_description } = this.state;
        let id = this.props.groupData.group_id;
        let data : any = {
            group_name :groupname,
            group_description :group_description,
            is_lsm:0 ,
            is_publish:1
        }
        this.props.editGroup(id, data);
        // event.preventDefault()
    }
    render() {
        console.log("this.props.groupData",this.props.groupData);
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isUpdated, message, menus, groupData } = this.props;
        const { mode, is_lsm, storesJson } = this.state;
        if (isUpdated) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        return (
            <Modal
            className="fade bd-example-modal-lg"
            show={this.props.editCategoryModal}
            size="md"
          >
            <Modal.Header>
            <Modal.Title>Edit Category</Modal.Title>
            <Button
              variant=""
              className="close"
              onClick={() => this.props.setEditCategoryModal(false)}
            >
              <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                <section className="forms">
                <div className="row">
                    <div className="col-12">
                            <div className="form-group">
                                <label className="form-control-label">Category Name <span className="text-danger">*</span></label>
                                <input id="groupname" type="text" name="groupname" onKeyDown={this.blockInvalidChar} value={this.state.groupname} required data-msg="Please enter Group Name" className="form-control" onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-control-label">Category Description 
                                {/* <span className="text-danger">*</span> */}
                                </label>
                                <input id="group_description" type="text" name="group_description" onKeyDown={this.blockInvalidChar} value={this.state.group_description} data-msg="Please enter Category Description" className="form-control" onChange={this.handleInputChange} />
                            </div>
                        </div>
                    </div>    
                    
                </section>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger light"
                    onClick={() => this.props.setEditCategoryModal(false)}
                >
                    Close
                </Button>
                <button onClick={this.handleSubmit} disabled={!this.isGroupReady()} className="btn btn-primary">Update Group</button>
            </Modal.Footer>
            </Modal>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        // groupData: state.menu.groupData,
        stores: state.menu.storesoptions,
        message: state.menu.message,
        isUpdated: state.menu.isUpdated,
        orderingModes: state.menu.orderModes
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        menusList: function () {
            dispatch(menusList())
        },
        editGroup: function (id: any, data: any) {
            dispatch(editGroup(id, data));
        },
        getGroup: function (id: number) {
            dispatch(getGroup(id));
        },
        storesList: () => {
            dispatch(storesListForMultiSelect())
        },
        OrderingModes: () => {
            dispatch(OrderingModes())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);