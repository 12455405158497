import { HANDLE_INPUT, GET_TRADEZONE, TRADEZONE_LIST, HANDLE_AREA_INPUT, TRADEAREA_LIST, GET_TRADEAREA } from '../actions/tradezoneType'
const initialState: any = {
    tradezonename: "",
    store: "",
    city: "",
    tradezones: [] || "",
    areaname: "",
    tradezone: "",
    tradeareas: [] || ""
}

const brandReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case HANDLE_INPUT: return {
            ...state,
            [action.input.name]: action.input.value
        }
        case TRADEZONE_LIST: return {
            ...state,
            tradezones: action.payload
        }
        case GET_TRADEZONE: return {
            ...state,
            tradezonename: action.payload.delivery_zone_name,
            city: action.payload.city_id
        }
        case HANDLE_AREA_INPUT: return {
            ...state,
            [action.input.name]: action.input.value
        }
        case TRADEAREA_LIST: return {
            ...state,
            tradeareas: action.payload
        }
        case GET_TRADEAREA: return {
            ...state,
            areaname: action.payload.area_name,
            tradezone: action.payload.delivery_zone_id,
            store: action.payload.store_id,
        }
        default: return state;
    }
}
export default brandReducer;