import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { EditPushProps, EditPushState } from '../../interface/notifications';
import { secretKey } from '../../../secret';
import { editNotification, getNotification, logoutUser } from '../../../redux';
class EditNotification extends Component<EditPushProps, EditPushState> {
    constructor(props: any) {
        super(props);
        this.state = {
            title: "",
            description: "",
            expiryDate: "",
            error: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.id;
        this.props.getNotification(id);
        document.title = "Push Notifications"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any, nextState: any) {
        this.setState({
            title: nextProps.notificationData.title,
            description: nextProps.notificationData.description,
            // expiryDate: nextProps.notificationData.expire_date,
        })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == "description" && event.target.value.length > 150) {
            this.setState({
                error: "Description must be 150 characters"
            });
        } else {
            this.setState({
                error: ""
            });
        }
    }
    isNotificationReady = () => {
        const { title, description, expiryDate } = this.state
        return (title !== "" && (description && description.length <= 150));
    }
    handleSubmit = (event: any) => {
        let { title, description, expiryDate } = this.state;
        let id = this.props.id;
        let data = {
            title: title,
            body: description,
            // expire_date: expiryDate
        }
        this.props.editNotification(id, data)
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { notificationData } = this.props;
        return (
        <>
        { this.props.id &&    
            <section className="forms">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label className="form-control-label">Title <span className="text-danger">*</span></label>
                                            <input id="title" type="text" value={this.state.title} name="title" required data-msg="Please enter title" className="form-control" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label className="form-control-label">Description <span className="text-danger">*</span></label>
                                            <input id="description" type="text" name="description" value={this.state.description} required data-msg="Please enter Description" className="form-control" onChange={this.handleInputChange} />
                                            <small className="form-text text-danger">{this.state.error}</small>
                                        </div>
                                    </div>
                                    {/* <div className="col">
                                        <div className="form-group">
                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                            <input id="expiryDate" type="date" name="expiryDate" value={notificationData.expire_date} required data-msg="Please enter Expire Date" className="form-control" onChange={this.handleInputChange} />
                                        </div>
                                    </div> */}
                                </div>
                                <div className="form-group float-right">
                                    <button onClick={this.handleSubmit} disabled={!this.isNotificationReady()} className="btn btn-primary">Update Notification</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            }
        </>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        // notificationData: state.notification.notificationData
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editNotification: function (id: any, data: any) {
            dispatch(editNotification(id, data));
        },
        getNotification: function (id: number) {
            dispatch(getNotification(id));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditNotification);