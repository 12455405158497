import React from "react";
import { Line } from "react-chartjs-2";

const LineChartAnalytics = ({ salesData }) => {
  const salesValues = salesData.map((item) => Math.round(item.sales)); 
  const maxSalesValue = Math.max(...salesValues);
  const maxTicks = Math.ceil(maxSalesValue / 100) * 100; 

  const data = {
    defaultFontFamily: "Poppins",
    labels: salesData.map((item) => item.month),
    datasets: [
      {
        data: salesValues,
        borderColor: "rgba(221, 47, 110, 1)",
        borderWidth: "2",
        backgroundColor: "rgba(221, 47, 110, 0.1)", 
        pointBackgroundColor: "rgba(221, 47, 110, 1)",
        pointRadius: 2, 
        spanGaps: true,
      },
    ],
  };

  const options = {
    legend: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: maxTicks,
            stepSize: maxTicks / 5,
            padding: 10,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 5,
          },
        },
      ],
    },
  };

  return <Line data={data} options={options} height={90} />;
};

export default LineChartAnalytics;
