import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { AddPushProps, AddPushState } from '../../interface/notifications';
import { secretKey } from '../../../secret';
import { addNotification, logoutUser } from '../../../redux';
import { Button, Modal } from 'react-bootstrap';
class AddNotification extends Component<AddPushProps, AddPushState> {
    constructor(props: any) {
        super(props);
        this.state = {
            title: "",
            description: "",
            expiryDate: "",
            error: ""
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "Push Notifications"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == "description" && event.target.value.length > 150) {
            this.setState({
                error: "Description must be 150 characters"
            });
        } else {
            this.setState({
                error: ""
            });
        }
    }
    isNotificationReady = () => {
        const { title, description } = this.state
        return (title !== "" && (description !== "" && description.length <= 150));
    }
    handleSaveBtnClick = (event: any) => {
        let { title, description, expiryDate } = this.state;
        let data = {
            title: title,
            body: description,
            // expire_date: expiryDate
        }
        this.props.addNotification(data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        return (
            <Modal
            className="fade bd-example-modal-lg"
            show={this.props.pushModal}
            size="lg"
          >
              <Modal.Header>
              <Modal.Title>Add Push Notification</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => this.props.setPushModal(false)}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>    
            <section className="forms">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label className="form-control-label">Title <span className="text-danger">*</span></label>
                                            <input id="title" type="text" name="title" required data-msg="Please enter Title" className="form-control" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label className="form-control-label">Description <span className="text-danger">*</span></label>
                                            <input id="description" type="text" name="description" required data-msg="Please enter Description" className="form-control" onChange={this.handleInputChange} />
                                            <small className="form-text text-danger">{this.state.error}</small>
                                        </div>
                                    </div>
                                    {/* <div className="col">
                                        <div className="form-group">
                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                            <input id="expiryDate" type="date" name="expiryDate" required data-msg="Please enter Expire Date" className="form-control" onChange={this.handleInputChange} />
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div className="form-group d-flex justify-content-end mt-4">
                                    <button className='btn btn-primary' disabled={!this.isNotificationReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <Modal.Footer>
                    <Button
                    variant="danger light"
                    onClick={() => this.props.setPushModal(false)}
                    >
                        Close
                    </Button>
                    <button className='btn btn-primary' disabled={!this.isNotificationReady()} onClick={this.handleSaveBtnClick}>Save</button>
                    
                    {/* <Button variant="" type="button" className="btn btn-primary">
                    Save Item
                    </Button> */}
            </Modal.Footer>
            </Modal>
        );
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addNotification: function (data: any) {
            dispatch(addNotification(data));
        }
    }
}
export default connect(null, mapDispatchToProps)(AddNotification);