export const GET_UNIT_OF_MEASURE= "GET_UNIT_OF_MEASURE"
export const UNIT_OF_MEASURES_LIST = "UNIT_OF_MEASURES_LIST" 
export const ITEM_TYPE_LIST = "ITEM_TYPE_LIST" 
export const ITEM_CATEGORY_LIST ="ITEM_CATEGORY_LIST"
export const COUNT_LEVEL_LIST = "COUNT_LEVEL_LIST"
export const RELIGIOUS_SUITABILITY_LIST ="RELIGIOUS_SUITABILITY_LIST"
export const NUTRITION_INFO_LIST ="NUTRITION_INFO_LIST"
export const NUTRITION_UNIT_LIST = "NUTRITION_UNIT_LIST"
export const RECIPE_TYPE_LIST = "RECIPE_TYPE_LIST"
export const RECIPE_CHANNEL_LIST = "RECIPE_CHANNEL_LIST"
export const GET_ITEM_UNIT_OF_MEASURES= "GET_ITEM_UNIT_OF_MEASURES"
export const GET_ITEM_NUTRITION = "GET_ITEM_NUTRITION"
export const GET_RECIPE = "GET_RECIPE"
export const CHANNELS_LIST ="CHANNELS_LIST"
export const GET_CHANNEL = "GET_CHANNEL"
export const GET_COMPANY = "GET_COMPANY"
export const CHILD_COMPANY_LIST = "CHILD_COMPANY_LIST"
export const GET_CHILD_COMPANY = "GET_CHILD_COMPANY"
export const COMPANY_LIST = "COMPANY_LIST"
export const GET_DOB = "GET_DOB"
export const TIME_ZONES = "TIME_ZONES"
export const TASK_FREQUENCY_LIST = "TASK_FREQUENCY_LIST"
export const WORKFLOW_LIST = "WORKFLOW_LIST"
export const GET_WORKFLOW = "GET_WORKFLOW"
export const CURRENCY_LIST = "CURRENCY_LIST"
export const GET_SALE_FOR_RECONCILE = "GET_SALE_FOR_RECONCILE"