import React, { Component } from 'react';
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import { AddGroupProps, AddGroupState } from '../../../interface/newMenu';
import { secretKey } from '../../../../secret';
import { addGroup, logoutUser, menusList, storesListForMultiSelect } from '../../../../redux';
import { addCategory, OrderingModes } from '../../../../redux/actions/menuAction';
import { Button, Modal } from 'react-bootstrap';
class AddGroup extends Component<AddGroupProps, AddGroupState> {
    orderModes: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.orderModes = [
            { value: 'online', label: 'Online' },
            { value: 'mobile', label: 'Mobile' },
            { value: 'callcenter', label: 'Call Center' }
        ];
        this.state = {
            groupname: "",
            group_description:"",
            menu: "",
            image: "",
            priority: "",
            mode: [],
            poscode: null,
            erpid: null,
            is_lsm: '0',
            storesJson: [],
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.menusList();
        this.props.storesList();
        this.props.OrderingModes();
        document.title = "Categories"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    fileSelectedHandler = (e: any) => {
        this.setState({ image: e.target.files })
    }
    isGroupReady = () => {
        const { groupname } = this.state
        return (groupname !== "");
        // if (is_lsm == "0") 
        // {
        //     return (groupname !== "" && mode.length > 0 && menu !== "" && image !== "");
        // } else if (is_lsm == "1") {
        //     return (groupname !== "" && mode.length > 0 && menu !== "" && image !== "" && storesJson.length > 0);
        // }
    }
    handleSaveBtnClick = () => {
        let { groupname, group_description } = this.state;
        let d1 = {
            group_name : groupname,
            group_description : group_description,
            mode : JSON.stringify([{"label":"Online","value":1},{"label":"Mobile","value":2}]),
            is_lsm:0 ,
            is_publish:1,
            menu_id:1
        }
        // const data: any = new FormData();
        
        // data.append('file', image[0])
        // data.append('group_name', groupname)
        // data.append('menu_id', menu)
        // data.append('mode', JSON.stringify(mode))
        // if (poscode !== null) {
        //     data.append('pos_code', poscode)
        // }
        // if (erpid !== null) {
        //     data.append('erp_id', erpid)
        // }
        // if (is_lsm == '0') {
        //     data.append('is_lsm', 0)
        // } else if (is_lsm == '1') {
        //     data.append('is_lsm', 1)
        //     data.append('stores_json', JSON.stringify(storesJson))
        // }
        // data.append('priority', priority)
        this.props.addCategory(d1);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message, menus } = this.props;
        const { is_lsm, storesJson } = this.state;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <Modal
            className="fade bd-example-modal-lg"
            show={this.props.categoryModal}
            size="md"
          >
                <Modal.Header>
            <Modal.Title>Add New Category</Modal.Title>
            <Button
              variant=""
              className="close"
              onClick={() => this.props.setCategoryModal(false)}
            >
              <span>&times;</span>
            </Button>
          </Modal.Header>
                <Modal.Body>
                <section className="forms">
                    <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                        {msg}
                    </p></strong>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-control-label">Category Name <span className="text-danger">*</span></label>
                                <input id="groupname" type="text" name="groupname" onKeyDown={this.blockInvalidChar} required data-msg="Please enter category name " className="form-control" onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-control-label">Category Description 
                                {/* <span className="text-danger">*</span> */}
                                </label>
                                <input id="groupname" type="text" name="group_description" onKeyDown={this.blockInvalidChar} required data-msg="Please enter category description" className="form-control" onChange={this.handleInputChange} />
                            </div>
                        </div>
                    </div>
                    {/* <div className="form-group d-flex justify-content-end mt-4">
                        <button className='btn btn-primary' disabled={!this.isGroupReady()} onClick={this.handleSaveBtnClick}>Save</button>
                    </div> */}
                </section>
                </Modal.Body>
                <Modal.Footer>
                <Button
                    variant="danger light"
                    onClick={() => this.props.setCategoryModal(false)}
                >
                    Close
                </Button>
                <button className='btn btn-primary' disabled={!this.isGroupReady()} onClick={this.handleSaveBtnClick}>Save</button>
                {/* <Button variant="" type="button" className="btn btn-primary">
                    Save Category
                </Button> */}
                </Modal.Footer>
                      
            </Modal>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        stores: state.menu.storesoptions,
        message: state.menu.message,
        isInserted: state.menu.isInserted,
        orderingModes: state.menu.orderModes
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        addCategory: function (data: any) {
            dispatch(addCategory(data));
        },
        menusList: function () {
            dispatch(menusList())
        },
        storesList: () => {
            dispatch(storesListForMultiSelect())
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
        OrderingModes: () => {
            dispatch(OrderingModes())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);