import Api from "../../components/Api";
import { SUPPLIERS_LIST } from "./supplierType";

export const suppliersList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/suppliers', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("response",response.data.successResponse)
                    dispatch({
                        type: SUPPLIERS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const suppliersLabelValueList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/suppliersLabelValue', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("response",response.data.successResponse)
                    dispatch({
                        type: SUPPLIERS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}