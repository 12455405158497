import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EditStoreProps, EditStoreState } from "../../interface/store";
import {
  addStates,
  countryList,
  editStore,
  getStore,
  statesList,
  tradeZoneList,
} from "../../../redux";
import { businessTypeList } from "../../../redux/actions/settingsAction";
import {
  channelList,
  getBackupStores,
  getTradeAreas,
  storesList,
  storeTypeList,
} from "../../../redux/actions/storeAction";
import { secretKey } from "../../../secret";
import { Nav, Tab } from "react-bootstrap";
toast.configure();

class EditStore extends Component<EditStoreProps, EditStoreState> {
  constructor(props: any) {
    super(props);
    this.state = {
      store_id: "",
      storename: "",
      storeemail: "",
      escalationemail: "",
      contact1: "",
      contact2: "",
      contact3: "",
      posno: "",
      brand: "",
      ntn: "",
      country: "",
      country_code: "",
      state: "",
      state_id: "",
      country_id: "",
      city: "",
      address: "",
      storeopen: "",
      storeclose: "",
      tradezonename: "",
      errormessage: "",
      expected_delivery_time: 0,
      kmlFile: null,
      kml: "",
      lat: 0,
      lng: 0,
      newlat: 0,
      newlng: 0,
      radius: 0,
      businessType: "",
      franchise_name: "",
      timeByDays: "",
      branchCode: "",
      fiscal_pos_code: "",
      aloha_branch_code: "",
      cloudKitchen: "Disabled",
      saleschannel: "",
      storetype: "",
      store_company: "",
      store_suppliers: [],
      is_closed: false,
      backupStore: [
        {
          storeId: "",
          backUpStoreId: "",
          priority: "",
        },
      ],
      tradeAreas: [
        {
          tradeArea: "",
          tradeZone: "",
        },
      ],
      mondayOpening: "",
      mondayClosing: "",
      mondayTime: {
        day: "Monday",
        opening: "",
        closing: "",
      },
      tuesdayTime: {
        day: "Tuesday",
        opening: "",
        closing: "",
      },
      wednesdayTime: {
        day: "Wednesday",
        opening: "",
        closing: "",
      },
      thursdayTime: {
        day: "Thursday",
        opening: "",
        closing: "",
      },
      fridayTime: {
        day: "Friday",
        opening: "",
        closing: "",
      },
      saturdayTime: {
        day: "Saturday",
        opening: "",
        closing: "",
      },
      sundayTime: {
        day: "Sunday",
        opening: "",
        closing: "",
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.getStore(id);
    this.props.countryList();
    this.props.statesList();
    this.props.businessTypeList();
    this.props.storesList();
    this.props.getBackupStores(id);
    // this.props.tradeZoneList();
    // this.props.getTradeAreas(id);
    this.props.channelsList();
    this.props.storeTypeList();
    // this.props.companyLabelValueList();
    // this.props.suppliersLabelValueList();
    document.title = "Stores";
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps && nextProps.storeData && nextProps.storeData.daysTiming) {
      var timings = JSON.parse(nextProps.storeData.daysTiming);
      if (timings.length > 0) {
        timings.forEach((element: any) => {
          console.log("element", element);
          const timeObj = {
            day: element.day,
            opening: element.opening,
            closing: element.closing,
          };
          if (element.day == "Monday") {
            // this.setState({ mondayTime: timeObj, mondayOpening: element.opening, mondayClosing: element.closing })
            this.setState({ mondayTime: timeObj });
          } else if (element.day == "Tuesday") {
            this.setState({ tuesdayTime: timeObj });
          } else if (element.day == "Wednesday") {
            this.setState({ wednesdayTime: timeObj });
          } else if (element.day == "Thursday") {
            this.setState({ thursdayTime: timeObj });
          } else if (element.day == "Friday") {
            this.setState({ fridayTime: timeObj });
          } else if (element.day == "Saturday") {
            this.setState({ saturdayTime: timeObj });
          } else if (element.day == "Sunday") {
            this.setState({ sundayTime: timeObj });
          }
        });
      }
    }

    var backupStores = nextProps && nextProps.backupStores;
    // var tradeAreas = nextProps && nextProps.tradeAreas;
    if (backupStores.length > 0) {
      var listBackUp: any = [];
      backupStores.forEach((elementBackup: any) => {
        const newlist: any = {
          backUpStoreId: elementBackup.backupStoreId.store_id,
          priority: elementBackup.priority,
          storeId: "",
        };
        listBackUp.push(newlist);
        // const backupStr: any = this.state.backupStore.concat(newlist);
        // alert("backupStores Length" + backupStr);
        this.setState({ backupStore: listBackUp });
      });
    }
    // if (tradeAreas.length > 0) {
    //     var listTradeArea: any = [];
    //     tradeAreas.forEach((elementArea: any) => {
    //         const newlist: any = { tradeArea: elementArea.area_name, tradeZone: elementArea.delivery_zone_id }
    //         listTradeArea.push(newlist);
    //         this.setState({ tradeAreas: listTradeArea });
    //     });
    // }
    this.setState({
      store_id: nextProps.storeData.store_id,
      storename: nextProps.storeData.store_name,
      storeemail: nextProps.storeData.store_email,
      branchCode: nextProps.storeData.branch_code,
      fiscal_pos_code: nextProps.storeData.fiscal_pos_code,
      escalationemail: nextProps.storeData.escalation_email,
      contact1: nextProps.storeData.contact1,
      contact2: nextProps.storeData.contact2,
      contact3: nextProps.storeData.contact3,
      posno: nextProps.storeData.pos_no,
      brand: nextProps.storeData.brand_name,
      city: nextProps.storeData.city,
      state_id: nextProps.storeData.state_id,
      ntn: nextProps.storeData.ntn_number,
      expected_delivery_time: nextProps.storeData.expected_delivery_time,
      country: nextProps.storeData.country_id,
      address: nextProps.storeData.address,
      tradezonename: nextProps.storeData.trade_zone_name,
      radius: nextProps.storeData.trade_zone_coverage,
      kml: nextProps.storeData.trade_zone_shape,
      lat: nextProps.storeData.lat,
      lng: nextProps.storeData.lng,
      timeByDays: nextProps.storeData.isDays == 1 ? true : false,
      businessType: nextProps.storeData.business_type_id,
      franchise_name: nextProps.storeData.franchise_name
        ? nextProps.storeData.franchise_name
        : "",
      cloudKitchen: nextProps.storeData.cloudKitchen,
      saleschannel: nextProps.storeData.sales_channel_id,
      storetype: nextProps.storeData.store_type_id,
      is_closed: nextProps.storeData.is_closed,
    });
    // if(nextProps.storeData && nextProps.companies && nextProps.companies.length > 0)
    // {
    //     for (let j = 0; j < nextProps.companies.length; j++) {
    //         const element = nextProps.companies[j];
    //         if(element.value == nextProps.storeData.company_id){
    //             this.setState({store_company:{label:element.label , value:element.value}})
    //             break;
    //         }

    //     }
    // }
    // if(nextProps.storeData && nextProps.storeData.store_suppliers && nextProps.storeData.store_suppliers.length>0)
    // {
    //     this.setState({store_suppliers:nextProps.storeData.store_suppliers})
    // }
    if (nextProps.storeData.aloha_branch_code) {
      this.setState({
        aloha_branch_code: nextProps.storeData.aloha_branch_code,
      });
    }
    if (nextProps.storeData.store_open_time == "00:00:00") {
      this.setState({
        storeopen: "",
        storeclose: "",
      });
    } else {
      this.setState({
        storeopen: nextProps.storeData.store_open_time,
        storeclose: nextProps.storeData.store_close_time,
      });
    }
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleStoreClosed = (event: { target: { name: any; value: any } }) => {
    const { is_closed } = this.state;
    this.setState({ is_closed: !is_closed });
  };
  handleSupplierCompanyInputChange(e: any) {
    this.setState({
      store_company: e,
    });
    this.setState({ store_suppliers: [] });
  }
  handleSupplierInputChange(e: any) {
    if (e && e.length > 0) {
      this.setState({
        store_suppliers: e,
      });
    } else {
      this.setState({ store_suppliers: [] });
    }
  }
  isStoreReady = () => {
    const {
      storename,
      storeemail,
      businessType,
      franchise_name,
      timeByDays,
      storeopen,
      storeclose,
      mondayTime,
      tuesdayTime,
      wednesdayTime,
      thursdayTime,
      fridayTime,
      saturdayTime,
      sundayTime,
    } = this.state;

    if (timeByDays) {
      return (
        storename !== "" &&
        businessType !== "" &&
        (businessType == 1 ? franchise_name !== "" : true) &&
        mondayTime.opening !== "" &&
        mondayTime.closing !== "" &&
        tuesdayTime.opening !== "" &&
        tuesdayTime.closing !== "" &&
        wednesdayTime.opening !== "" &&
        wednesdayTime.closing !== "" &&
        thursdayTime.opening !== "" &&
        thursdayTime.closing !== "" &&
        fridayTime.opening !== "" &&
        fridayTime.closing !== "" &&
        saturdayTime.opening !== "" &&
        saturdayTime.closing !== "" &&
        sundayTime.opening !== "" &&
        sundayTime.closing !== ""
      );
    } else {
      return (
        storename !== "" &&
        businessType !== "" &&
        (businessType == 1 ? franchise_name !== "" : true) &&
        storeemail !== "" &&
        storeopen !== "" &&
        storeclose !== ""
      );
    }
  };
  handleRadius = (radius: any) => {
    //to Get the Radius from the map component and set in state
    this.setState({
      radius: radius,
    });
  };
  fileSelectedHandler = (e: any) => {
    this.setState({ kmlFile: e.target.files });
  };
  handleMapData = (obj: any) => {
    //to Get the values from the map component and set in state
    this.setState({
      address: obj.address,
      city: obj.city,
      // state: obj.state,
      country: obj.country,
      country_code: obj.country_code,
      lat: obj.lat,
      lng: obj.lng,
    });
    let { countries, states } = this.props;
    let stateExist = false;
    // if (obj.state !== this.state.state) {
    countries.forEach((country) => {
      if (country.country_code === obj.country_code) {
        for (let i = 0; i < states.length; i++) {
          if (country.country_id == states[i].country_id) {
            if (country.country_name == states[i].state_name) {
              stateExist = true;
              this.setState({ country_id: country.country_id });
              // this.setState({ state_id: states[i].state_id, country_id: country.country_id })
              break;
            } else if (obj.state == states[i].state_name) {
              stateExist = true;
              this.setState({ country_id: country.country_id });
              // this.setState({ state_id: states[i].state_id, country_id: country.country_id })
              break;
            }
          }
        }
        // if (stateExist === false) {
        //     this.setState({ state_id: "" })
        //     let stateObj = {
        //         state_name: obj.state,
        //         country_id: country.country_id,
        //         tax_percent: 0
        //     }
        //     this.props.addStates(stateObj, "store");
        // }
      }
    });
    // }
  };
  checkAreaNameDuplication = () => {
    const { tradeAreas } = this.state;
    var result: any = [];
    // To Remove all Duplicate objects
    var array: any = tradeAreas,
      seen = Object.create(null),
      result = array.filter((o: any) => {
        var key = ["tradeZone", "tradeArea"].map((k) => o[k]).join("|");
        if (!seen[key]) {
          seen[key] = true;
          return true;
        }
      });
    if (array.length !== result.length) {
      //check original array length after remove duplicates
      return true;
    } else {
      return false;
    }
  };
  blockInvalidChar = (e: any) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  handleSubmit = async (event: any) => {
    let daysTiming: any = [];
    let {
      storename,
      storeemail,
      contact1,
      contact2,
      storeopen,
      storeclose,
      businessType,
      timeByDays,
      backupStore,
      mondayTime,
      tuesdayTime,
      wednesdayTime,
      thursdayTime,
      fridayTime,
      saturdayTime,
      sundayTime,
      franchise_name,
      is_closed,
    } = this.state;
    let id = this.props.match.params.id;
    let { countries } = this.props;
    let DuplicationFlag: any = await this.checkAreaNameDuplication();
    if (DuplicationFlag) {
      toast.error("Duplicate areas found for one tradezone", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } else {
      let data: any = {};
      if (timeByDays) {
        daysTiming.push(mondayTime);
        daysTiming.push(tuesdayTime);
        daysTiming.push(wednesdayTime);
        daysTiming.push(thursdayTime);
        daysTiming.push(fridayTime);
        daysTiming.push(saturdayTime);
        daysTiming.push(sundayTime);
        data.daysTiming = JSON.stringify(daysTiming);
      } else {
        data.store_open_time = storeopen;
        data.store_close_time = storeclose;
        data.daysTiming = "";
      }

      if (timeByDays === true) {
        data.timeByDays = 1;
      } else {
        data.timeByDays = 0;
      }
      data.store_name = storename;
      data.store_email = storeemail;
      data.contact1 = contact1;
      data.contact2 = contact2;
      data.is_active = 1;
      data.business_type_id = businessType;
      data.franchise_name = businessType == 1 ? franchise_name : "";
      data.backupStore = JSON.stringify(backupStore);
      data.is_closed = is_closed ? 1 : 0;
      console.log(data);
      this.props.editStore(id, data);
    }
  };
  handleTimeByDays = (event: { target: { name: any; value: any } }) => {
    const { timeByDays } = this.state;
    this.setState({ timeByDays: !timeByDays });
  };
  dayOpeningTime = (e: any) => {
    const { name, value } = e.target;
    if (name == "mondayTime") {
      const list: any = this.state.mondayTime;
      list.opening = value;
      this.setState({ mondayTime: list });
    } else if (name == "tuesdayTime") {
      const list: any = this.state.tuesdayTime;
      list.opening = value;
      this.setState({ tuesdayTime: list });
    } else if (name == "wednesdayTime") {
      const list: any = this.state.wednesdayTime;
      list.opening = value;
      this.setState({ wednesdayTime: list });
    } else if (name == "thursdayTime") {
      const list: any = this.state.thursdayTime;
      list.opening = value;
      this.setState({ thursdayTime: list });
    } else if (name == "fridayTime") {
      const list: any = this.state.fridayTime;
      list.opening = value;
      this.setState({ fridayTime: list });
    } else if (name == "saturdayTime") {
      const list: any = this.state.saturdayTime;
      list.opening = value;
      this.setState({ saturdayTime: list });
    } else if (name == "sundayTime") {
      const list: any = this.state.sundayTime;
      list.opening = value;
      this.setState({ sundayTime: list });
    }
  };

  dayClosingTime = (e: any) => {
    const { name, value } = e.target;
    if (name == "mondayTime") {
      const list: any = this.state.mondayTime;
      list.closing = value;
      this.setState({ mondayTime: list });
    } else if (name == "tuesdayTime") {
      const list: any = this.state.tuesdayTime;
      list.closing = value;
      this.setState({ tuesdayTime: list });
    } else if (name == "wednesdayTime") {
      const list: any = this.state.wednesdayTime;
      list.closing = value;
      this.setState({ wednesdayTime: list });
    } else if (name == "thursdayTime") {
      const list: any = this.state.thursdayTime;
      list.closing = value;
      this.setState({ thursdayTime: list });
    } else if (name == "fridayTime") {
      const list: any = this.state.fridayTime;
      list.closing = value;
      this.setState({ fridayTime: list });
    } else if (name == "saturdayTime") {
      const list: any = this.state.saturdayTime;
      list.closing = value;
      this.setState({ saturdayTime: list });
    } else if (name == "sundayTime") {
      const list: any = this.state.sundayTime;
      list.closing = value;
      this.setState({ sundayTime: list });
    }
  };

  handleBackupStoreInputChange = (e: any, index: any) => {
    if (e && e.target) {
      const { name, value } = e.target;
      const list: any = this.state.backupStore;
      list[index][name] = value;
      this.setState({ backupStore: list });
    } else {
      const list: any = this.state.backupStore;
      if (e && e.value > 0) {
        list[index]["backUpStoreId"] = e.value;
      } else {
        list[index]["backUpStoreId"] = "";
      }
      this.setState({ backupStore: list });
    }
  };
  handleTradeAreaInputChange = (e: any, index: any) => {
    if (e && e.target) {
      const { name, value } = e.target;
      const list: any = this.state.tradeAreas;
      list[index][name] = value;
      this.setState({ tradeAreas: list });
    } else {
      const list: any = this.state.tradeAreas;
      if (e && e.value > 0) {
        list[index]["tradeZone"] = e.value;
      } else {
        list[index]["tradeZone"] = "";
      }
      this.setState({ tradeAreas: list });
    }
  };

  handleAddBackupStore = (i: any) => {
    const list: any = this.state.backupStore;
    if (list[i]["backUpStoreId"] == "" || list[i]["priority"] == "") {
      alert("Please fill in current item");
    } else if (i == "1" || i > 1) {
      alert("Sorry you can select only 2 Backup Stores");
    } else {
      const newlist: any = { backUpStoreId: "", priority: "", storeId: "" };
      const backupStr: any = this.state.backupStore.concat(newlist);
      this.setState({ backupStore: backupStr });
    }
  };

  handleAddTradeArea = (i: any) => {
    const list: any = this.state.tradeAreas;
    if (list[i]["tradeZone"] == "" || list[i]["tradeArea"] == "") {
      alert("Please fill in current item");
    } else {
      const newlist: any = { tradeZone: "", tradeArea: "" };
      const tradeArea: any = this.state.tradeAreas.concat(newlist);
      this.setState({ tradeAreas: tradeArea });
    }
  };
  handleRemoveBackupStore = (index: any) => {
    const list = this.state.backupStore;
    list.splice(index, 1);
    this.setState({ backupStore: list });
  };
  handleRemoveTradeArea = (index: any) => {
    const list = this.state.tradeAreas;
    list.splice(index, 1);
    this.setState({ tradeAreas: list });
  };
  handleBusinessType = (e: any, index: any) => {
    if (e && e.value > 0) {
      this.setState({ businessType: e.value });
    } else {
      this.setState({ businessType: "" });
    }
  };
  handleStates = (e: any, index: any) => {
    if (e && e.value > 0) {
      this.setState({ state_id: e.value });
    } else {
      this.setState({ state_id: "" });
    }
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    const {
      isUpdated,
      message,
      business_list,
      stores,
      states,
      tradezones,
      channels,
      types,
      companies,
      suppliers,
    } = this.props;
    const {
      franchise_name,
      storename,
      brand,
      storeopen,
      state_id,
      storeemail,
      branchCode,
      fiscal_pos_code,
      aloha_branch_code,
      expected_delivery_time,
      escalationemail,
      posno,
      ntn,
      contact1,
      contact2,
      contact3,
      storeclose,
      errormessage,
      businessType,
      timeByDays,
      mondayTime,
      tuesdayTime,
      wednesdayTime,
      thursdayTime,
      fridayTime,
      saturdayTime,
      sundayTime,
      backupStore,
      tradeAreas,
      cloudKitchen,
      storetype,
      saleschannel,
    } = this.state;
    if (isUpdated) {
      msg = errormessage ? errormessage : message;
      messcolor = "green";
    } else {
      msg = errormessage ? errormessage : message;
      messcolor = "red";
    }
    return (
      <div className="page">
        {/* <CheckChanges path="/edit-store" /> */}
        {/* <Topbar /> */}
        <div className="page-content">
          {/* <Sidebar /> */}
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header pb-1">
              <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>Stores Management</h4>
                </div>
              </div>
            </header>
            {/*  Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/stores" className="text-primary">
                    Stores
                  </Link>
                </li>
                <li className="breadcrumb-item active">Edit Store</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p
                            className="text-center font-weight-light mb-4"
                            style={{ color: messcolor }}
                          >
                            {msg}
                          </p>
                        </strong>
                        <Tab.Container defaultActiveKey={"one"}>
                          <Nav as="ul" className="nav-pills mb-4 light">
                            <Nav.Item as="li" key={1}>
                              <Nav.Link eventKey={"one"}>Info</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" key={2}>
                              <Nav.Link eventKey={"two"}>Contact</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" key={3}>
                              <Nav.Link eventKey={"three"}>Timings</Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content className="pt-4">
                            <Tab.Pane eventKey={"one"} key={1}>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-control-label ">
                                      Store Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      id="storename"
                                      type="text"
                                      name="storename"
                                      defaultValue={storename}
                                      required
                                      data-msg="Please enter Store name"
                                      className="form-control"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className="form-control-label">
                                      Brand Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      id="brandname"
                                      type="text"
                                      name="brandname"
                                      defaultValue={brand}
                                      disabled
                                      data-msg="Please enter Brand name"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div
                                  {...(businessType == "1"
                                    ? { className: "col-md-3" }
                                    : { className: "col-3" })}
                                >
                                  <div className="form-group">
                                    <label className="form-control-label">
                                      Business Type{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Select
                                      name="businessType"
                                      isClearable
                                      options={business_list}
                                      className="text-capitalize select mt-2"
                                      classNamePrefix="select"
                                      value={business_list.map(
                                        (element: any) => {
                                          if (element.id == businessType) {
                                            return {
                                              label: element.name,
                                              value: businessType,
                                            };
                                          }
                                        }
                                      )}
                                      onChange={(e, i) =>
                                        this.handleBusinessType(e, i)
                                      }
                                    />
                                  </div>
                                </div>
                                {businessType == "1" && (
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-control-label">
                                        Franchise Name{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        id="franchisename"
                                        type="text"
                                        name="franchise_name"
                                        defaultValue={franchise_name}
                                        data-msg="Please enter franchise name"
                                        className="form-control"
                                        onChange={this.handleInputChange}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="row">
                                <div className="col">
                                  <div className="form-group">
                                    <label className="form-control-label">
                                      Store Type{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <select
                                      name="storetype"
                                      value={storetype}
                                      className="form-control text-capitalize mt-2"
                                      required
                                    >
                                      {types &&
                                        types.map((type, index) => (
                                          <option
                                            key={index}
                                            value={type.store_type_id}
                                          >
                                            {type.store_type_name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </div>

                                {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Expected Time <span className="text-danger">* (In minutes)</span></label>
                                                            <input id="expected_delivery_time" type="number" value={expected_delivery_time} name="expected_delivery_time" required data-msg="Please enter expected time" className="form-control" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">States <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="state_id"
                                                                isClearable
                                                                options={states}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                value={states.map((element: any) => {
                                                                    if (element.state_id == state_id) {
                                                                        return { label: element.state_name, value: state_id }
                                                                    }
                                                                })}
                                                                onChange={(e, i) => this.handleStates(e, i)}
                                                            />
                                                        </div>
                                                    </div> */}
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                          <Tab.Content>
                            <Tab.Pane eventKey={"two"} key={2}>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="form-control-label">
                                      Email Contact{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      id="storeemail"
                                      type="email"
                                      name="storeemail"
                                      defaultValue={storeemail}
                                      required
                                      data-msg="Please enter Store Email"
                                      className="form-control"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="form-control-label">
                                      Contact 1
                                    </label>
                                    <input
                                      id="contact1"
                                      type="text"
                                      name="contact1"
                                      defaultValue={contact1}
                                      className="form-control"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="form-control-label">
                                      Contact 2
                                    </label>
                                    <input
                                      id="contact2"
                                      type="text"
                                      name="contact2"
                                      defaultValue={contact2}
                                      className="form-control"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Email Escalation</label>
                                                            <input id="escalationemail" type="email" name="escalationemail" defaultValue={escalationemail} className="form-control" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                <div className="col">
                                  {/* <div className="form-group">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Pos no</label>
                                                                <input id="posno" type="text" name="posno" value={posno} className="form-control" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div> */}
                                </div>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                          <Tab.Content>
                            <Tab.Pane eventKey={"three"} key={3}>
                              {!timeByDays && (
                                <div className="row">
                                  <div className="col">
                                    <div className="form-group">
                                      <label className="form-control-label">
                                        Opening Time{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        id="storeopen"
                                        type="time"
                                        name="storeopen"
                                        defaultValue={storeopen}
                                        required
                                        data-msg="Please enter opening time"
                                        className="form-control"
                                        onChange={this.handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="form-group">
                                      <label className="form-control-label">
                                        Closing Time{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        id="storeclose"
                                        type="time"
                                        name="storeclose"
                                        defaultValue={storeclose}
                                        required
                                        data-msg="Please enter closing time"
                                        className="form-control"
                                        onChange={this.handleInputChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="row">
                                <div className="col-6">
                                  <div className="i-checks">
                                    <input
                                      id="checkboxCustom1"
                                      type="checkbox"
                                      name="timeByDays"
                                      checked={timeByDays == true && true}
                                      onChange={this.handleTimeByDays}
                                      className="checkbox-template"
                                    />
                                    <label
                                      style={{ marginLeft: "1rem" }}
                                      htmlFor="checkboxCustom1"
                                    >
                                      Add Time by Week Days
                                    </label>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="i-checks">
                                    <input
                                      id="checkboxCustom1"
                                      type="checkbox"
                                      name="is_closed"
                                      checked={
                                        this.state.is_closed == true && true
                                      }
                                      onChange={this.handleStoreClosed}
                                      className="checkbox-template"
                                    />
                                    <label
                                      style={{ marginLeft: "1rem" }}
                                      htmlFor="checkboxCustom1"
                                    >
                                      Closed
                                    </label>
                                  </div>
                                </div>
                              </div>

                              {timeByDays && (
                                <div>
                                  <div className="row">
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Day{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="day"
                                          type="text"
                                          readOnly
                                          value={"Monday"}
                                          name="day"
                                          required
                                          data-msg="Please enter Opening Time"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Opening Time{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="mondayTime"
                                          type="time"
                                          name="mondayTime"
                                          defaultValue={mondayTime.opening}
                                          required
                                          data-msg="Please enter Opening Time"
                                          className="form-control"
                                          onChange={(e) =>
                                            this.dayOpeningTime(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Closing Time{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="mondayTime"
                                          type="time"
                                          name="mondayTime"
                                          defaultValue={mondayTime.closing}
                                          required
                                          className="form-control"
                                          onChange={(e) =>
                                            this.dayClosingTime(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Day{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="day"
                                          type="text"
                                          readOnly
                                          value={"Tuesday"}
                                          name="day"
                                          required
                                          data-msg="Please enter Opening Time"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Opening Time{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="tuesdayTime"
                                          type="time"
                                          name="tuesdayTime"
                                          defaultValue={tuesdayTime.opening}
                                          required
                                          data-msg="Please enter Opening Time"
                                          className="form-control"
                                          onChange={(e) =>
                                            this.dayOpeningTime(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Closing Time{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="tuesdayTime"
                                          type="time"
                                          name="tuesdayTime"
                                          defaultValue={tuesdayTime.closing}
                                          required
                                          className="form-control"
                                          onChange={(e) =>
                                            this.dayClosingTime(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Day{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="day"
                                          type="text"
                                          readOnly
                                          value={"Wednesday"}
                                          name="day"
                                          required
                                          data-msg="Please enter Opening Time"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Opening Time{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="wednesdayTime"
                                          type="time"
                                          name="wednesdayTime"
                                          defaultValue={wednesdayTime.opening}
                                          required
                                          data-msg="Please enter Opening Time"
                                          className="form-control"
                                          onChange={(e) =>
                                            this.dayOpeningTime(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Closing Time{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="wednesdayTime"
                                          type="time"
                                          name="wednesdayTime"
                                          defaultValue={wednesdayTime.closing}
                                          required
                                          className="form-control"
                                          onChange={(e) =>
                                            this.dayClosingTime(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Day{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="day"
                                          type="text"
                                          readOnly
                                          value={"Thursday"}
                                          name="day"
                                          required
                                          data-msg="Please enter Opening Time"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Opening Time{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="thursdayTime"
                                          type="time"
                                          name="thursdayTime"
                                          defaultValue={thursdayTime.opening}
                                          required
                                          data-msg="Please enter Opening Time"
                                          className="form-control"
                                          onChange={(e) =>
                                            this.dayOpeningTime(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Closing Time{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="thursdayTime"
                                          type="time"
                                          name="thursdayTime"
                                          defaultValue={thursdayTime.closing}
                                          required
                                          className="form-control"
                                          onChange={(e) =>
                                            this.dayClosingTime(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Day{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="day"
                                          type="text"
                                          readOnly
                                          value={"Friday"}
                                          name="day"
                                          required
                                          data-msg="Please enter Opening Time"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Opening Time{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="fridayTime"
                                          type="time"
                                          name="fridayTime"
                                          defaultValue={fridayTime.opening}
                                          required
                                          data-msg="Please enter Opening Time"
                                          className="form-control"
                                          onChange={(e) =>
                                            this.dayOpeningTime(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Closing Time{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="fridayTime"
                                          type="time"
                                          name="fridayTime"
                                          defaultValue={fridayTime.closing}
                                          required
                                          className="form-control"
                                          onChange={(e) =>
                                            this.dayClosingTime(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Day{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="day"
                                          type="text"
                                          readOnly
                                          value={"Saturday"}
                                          name="day"
                                          required
                                          data-msg="Please enter Opening Time"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Opening Time{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="saturdayTime"
                                          type="time"
                                          name="saturdayTime"
                                          defaultValue={saturdayTime.opening}
                                          required
                                          data-msg="Please enter Opening Time"
                                          className="form-control"
                                          onChange={(e) =>
                                            this.dayOpeningTime(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Closing Time{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="saturdayTime"
                                          type="time"
                                          name="saturdayTime"
                                          defaultValue={saturdayTime.closing}
                                          required
                                          className="form-control"
                                          onChange={(e) =>
                                            this.dayClosingTime(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Day{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="day"
                                          type="text"
                                          readOnly
                                          value={"Sunday"}
                                          name="day"
                                          required
                                          data-msg="Please enter Opening Time"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Opening Time{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="sundayTime"
                                          type="time"
                                          name="sundayTime"
                                          defaultValue={sundayTime.opening}
                                          required
                                          data-msg="Please enter Opening Time"
                                          className="form-control"
                                          onChange={(e) =>
                                            this.dayOpeningTime(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Closing Time{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="sundayTime"
                                          type="time"
                                          name="sundayTime"
                                          defaultValue={sundayTime.closing}
                                          required
                                          className="form-control"
                                          onChange={(e) =>
                                            this.dayClosingTime(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>

                        <div style={{ width: "100%" }}></div>

                        <hr />

                        <div className="form-group float-right mt-3">
                          <button
                            onClick={() => {
                              this.props.history.push("/stores");
                            }}
                            className="btn btn-danger mr-2"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={this.handleSubmit}
                            disabled={!this.isStoreReady()}
                            className="btn btn-primary"
                          >
                            Update Store
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    storeData: state.store.storeData,
    message: state.store.message,
    isUpdated: state.store.isUpdated,
    countries: state.store.countries,
    channels: state.store.channels,
    states: state.setting.states,
    types: state.store.types,
    business_list: state.store.businessType,
    backupStores: state.store.getBackupStores,
    tradeAreas: state.store.getTradeAreas,
    stores: state.store.data,
    tradezones: state.tradezone.tradezones,
    companies: state.uom.companies,
    suppliers: state.supplier.data,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    editStore: function (id: any, data: any) {
      dispatch(editStore(id, data));
    },
    addStates: function (data: any, type: any) {
      dispatch(addStates(data, type));
    },
    getStore: function (id: number) {
      dispatch(getStore(id));
    },
    countryList: function () {
      dispatch(countryList());
    },
    statesList: function () {
      dispatch(statesList("store"));
    },
    businessTypeList: function () {
      dispatch(businessTypeList());
    },
    getBackupStores: function (id: number) {
      dispatch(getBackupStores(id));
    },
    storesList: function () {
      dispatch(storesList());
    },
    tradeZoneList: () => {
      dispatch(tradeZoneList());
    },
    getTradeAreas: function (id: number) {
      dispatch(getTradeAreas(id));
    },
    channelsList: function () {
      dispatch(channelList());
    },
    storeTypeList: function () {
      dispatch(storeTypeList());
    },
    // companyLabelValueList : function (){
    //     dispatch(companyLabelValueList());
    // },
    // suppliersLabelValueList : function(){
    //     dispatch(suppliersLabelValueList());
    // },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditStore);
