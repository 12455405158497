import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { AddRoleProps, AddRoleState } from '../../interface/user';
import { secretKey } from '../../../secret';
import { addRole, logoutUser } from '../../../redux';
// import CheckChanges from '../../components/confirmOnLeave'

class AddRole extends Component<AddRoleProps, AddRoleState> {
    constructor(props: any) {
        super(props);
        this.state = {
            rolename: "",
            type: "cms"
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "Roles"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    isRoleReady = () => {
        const { rolename,type } = this.state
        return (rolename !== "" && type!=="");
    }
    handleSubmit = (event: any) => {
        let { rolename, type } = this.state;
        let data = {
            role_name: rolename,
            type: type
        }
        this.props.addRole(data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message } = this.props;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                {/* <CheckChanges path="/add-role" /> */}
                {/* <Topbar /> */}
                <div className="page-content">
                    {/* <Sidebar /> */}
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Roles Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/roles" className="text-primary">Roles</Link></li>
                                <li className="breadcrumb-item active">Add Role</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">Role Name <span className="text-danger">*</span></label>
                                                            <input id="rolename" type="text" name="rolename" value={this.state.rolename} required data-msg="Please enter role name" className="form-control" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">Type <span className="text-danger">*</span></label>
                                                            <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                                                <option value='cms'>Cms</option>
                                                                <option value='store'>Store</option>
                                                                <option value='crm'>Crm</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isRoleReady()} onClick={this.handleSubmit}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <Footer /> */}
                    </div>
                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        message: state.setting.message,
        isInserted: state.setting.isInserted
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addRole: function (data: any) {
            dispatch(addRole(data));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddRole);