import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
// import Dropdownblog2 from './Dropdownblog2';
import Api from '../../../../components/Api';
import moment from 'moment';

const CustomerList = () => {
  const [tableData, setTableData] : any = useState(null);

  useEffect(() => {
    let obj :any = {};
    obj.days = 0;

    let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/customers`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.successResponse)
			// Assuming response.data.orders contains the array of orders from the API response
          const orders = response.data.successResponse;
		    console.log("orders",orders)
          // Map the orders array to match the format of rows in the data object
          const rows = orders.map((order:any) => ({
            customer_id: order.customer_id,
            first_name: order.first_name,
            last_name: order.last_name,
            email_address : order.email_address,
            phone_number: order.phone_number,
            status:order.is_active == 1  ?  (
              <Link to={""} className="btn bgl-success text-success btn-sm">
                Active
              </Link>
            ) :""
            // dropdown: <Dropdownblog2 />
          }));
		  const columns: any = [
            { label: 'Customer ID', field: 'customer_id', sort: 'asc', width: 100 },
            { label: 'First Name', field: 'first_name', sort: 'asc' },
            { label: 'Last Name', field: 'last_name', sort: 'asc' },
            { label: 'Email', field: 'email_address', sort: 'asc' },
            { label: 'Phone', field: 'phone_number', sort: 'asc' },
            { label: 'Status', field: 'status', sort: 'asc' },
            // { label: 'Action', field: 'dropdown', sort: 'asc' }
          ];
          // Update the table data state with the new columns and rows
          setTableData({
            columns: columns,
            rows: rows
          });
        }
      });
  }, []);

  if (!tableData) {
    // You can return a loading state here if needed
    return <div>Loading...</div>;
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <div className="display mb-4 dataTablesCard no-footer">
              <MDBDataTable striped small data={tableData} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerList;
