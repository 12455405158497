import React, { Component } from "react";
import { Link } from "react-router-dom";

type PageTitleState = {
	pageHeading? :any,
	motherMenu? : any, 
	activeMenu? :any,
	pageContent?:any
}
class PageTitle extends Component<PageTitleState, {}> {
	constructor(props: any) {
        super(props);
        this.state = {
        
        }
    }
	
	render()
	{
		let path = window.location.pathname.split("/");
		return (
			<div className="page-titles">
				<h4>{this.props.activeMenu}{this.props.pageHeading}</h4>	
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={`/${path[path.length - 1]}`}>{this.props.motherMenu}</Link>
					</li>
					<li className="breadcrumb-item active">
					<Link to={`/${path[path.length - 1]}`}>{this.props.activeMenu}</Link>
					</li>
				</ol>
			</div>
		  );
		};
	}
  

export default PageTitle;
