import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import Select from 'react-select';
import { AddDiscountProps, AddDiscountState } from '../../interface/discounts';
import { secretKey } from '../../../secret';
import { addDiscount, brandsList, groupsListForMultiSelect, itemsListForMultiSelect, logoutUser, menusList } from '../../../redux';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
class AddDiscount extends Component<AddDiscountProps, AddDiscountState> {
    constructor(props: any) {
        super(props);
        this.state = {
            discount_name:"",
            discount_description:"",
            discountvalue: "",
            startDate : "",
            expiryDate: "",
            percent: "",
            mode: [{ value: 'online', label: 'Online' }],
            channel: "delivery",
            type: "menu",
            discountType: "value",
            multiJson: [],
            type_id: ""
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDiscountLevel = this.handleDiscountLevel.bind(this);
        // this.handleDiscountValue=this.handleDiscountValue.bind(this);
        this.handleDiscountType = this.handleDiscountType.bind(this);
    }
    componentDidMount() {
        document.title = "Discounts"
        this.props.itemsListForMultiSelect();
        this.props.menusList();
        this.props.groupsList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    // handleDiscountType(event: { target: { name: any; value: any; }; }) {
    //     this.setState({
    //         type_id:"",
    //         [event.target.name]: event.target.value,
    //     }); 
    // }
    handleGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiJson: e });
        }
    };
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiJson: e });
        }
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
        }
    };
    handleDiscountType(event: { target: { name: any; value: any; }; }) {
        this.setState({
            percent: "",
            discountvalue: "",
            [event.target.name]: event.target.value,
        });
    }

    isDiscountReady = () => {
        const { expiryDate, mode, discountType, discountvalue, type, type_id, multiJson, percent,startDate } = this.state;
        if (type == "menu") {
            return (startDate !== "" && expiryDate !== "" && mode.length > 0 && type_id !== "" && discountType !== "" && (percent !== "" || discountvalue !== ""));
        } else if (type == "group" || type == "item") {
            return (startDate !== "" && expiryDate !== "" && mode.length > 0 && multiJson.length > 0 && discountType !== "" && (percent !== "" || discountvalue !== ""));
        }
    }
    handleSaveBtnClick = (history: any) => {
        let { discount_name,discount_description,discountvalue, expiryDate, mode, discountType, type, type_id, multiJson, percent, startDate } = this.state;
        let data: any = {
            discount_name: discount_name,
            discount_description:discount_description,
            discount_value: discountvalue,
            discount_type: discountType,
            start_date: moment(startDate).utc(false).format('YYYY-MM-DD HH:mm:ss'),
            expire_date: expiryDate,
            mode: JSON.stringify(mode),
            type: type,
            percent: percent
        }
        if (type == "menu") {
            data.type_id = type_id;
        } else if (type == "group" || type == "item") {
            data.items_json = JSON.stringify(multiJson);
        }
        this.props.addDiscount(data, history);
        this.props.setDiscountModal(false)
    }

    handleDiscountLevel(event: { target: { name: any; value: any; }; }) {
        this.setState({
            type_id: "",
            [event.target.name]: event.target.value,
        });
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message, menus, items } = this.props;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <Modal
          className="fade bd-example-modal-lg"
          show={this.props.discountModal}
          size="lg"
        >
            <Modal.Header>
            <Modal.Title>Add Discount</Modal.Title>
            <Button
              variant=""
              className="close"
              onClick={() => this.props.setDiscountModal(false)}
            >
              <span>&times;</span>
            </Button>
          </Modal.Header>
           <section className="forms">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                        {msg}
                                    </p></strong>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-control-label">Discount name <span className="text-danger">*</span></label>
                                                <input id="couponname" type="text" name="discount_name" required data-msg="Please enter Discount Name" className="form-control" onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-control-label">Description</label>
                                                <input id="coupondesc" type="text" name="discount_description" required data-msg="Please enter Description" className="form-control" onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-control-label">Discount Level <span className="text-danger">*</span></label>
                                                <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleDiscountLevel}>
                                                    <option value='menu'>Menu</option>
                                                    <option value='group'>Category</option>
                                                    <option value='item'>Product</option>
                                                </select>
                                            </div>
                                        </div>
                                        {this.state.type === "menu" &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-control-label">Select Menu <span className="text-danger">*</span></label>
                                                    <select name="type_id" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                        <option>Select Menu</option>
                                                        {menus &&
                                                            menus.map((menu: any, index: any) => (
                                                                <option key={index} value={menu.menu_id}>{menu.menu_name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        }

                                        {this.state.type === "group" &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-control-label">Select Categories <span className="text-danger">*</span></label>
                                                    <Select
                                                        isMulti
                                                        name="groups"
                                                        options={this.props.groups}
                                                        className="text-capitalize basic-multi-select mt-2"
                                                        classNamePrefix="select"
                                                        onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {this.state.type === "item" &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-control-label">Select Products <span className="text-danger">*</span></label>
                                                    <Select
                                                        isMulti
                                                        name="items"
                                                        options={items}
                                                        className="text-capitalize basic-multi-select mt-2"
                                                        classNamePrefix="select"
                                                        onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-control-label">Discount Type <span className="text-danger">*</span></label>
                                                <select name="discountType" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleDiscountType}>
                                                    <option value='value'>Value</option>
                                                    <option value='percentage'>Percentage</option>
                                                </select>
                                            </div>
                                        </div>

                                        {this.state.discountType === "percentage" &&
                                            <div className="col">
                                                <div className="form-group">
                                                    <label className="form-control-label">Discount Percentage <span className="text-danger">*</span></label>
                                                    <input id="percent" type="text" name="percent" required data-msg="Please enter Coupon Value" className="form-control" onChange={this.handleInputChange} />
                                                </div>
                                            </div>
                                        }
                                        {this.state.discountType === "value" &&
                                            <div className="col">
                                                <div className="form-group">
                                                    <label className="form-control-label">Discount value <span className="text-danger">*</span></label>
                                                    <input id="discountvalue" type="text" name="discountvalue" required data-msg="Please enter Discount Value" className="form-control" onChange={this.handleInputChange} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-control-label">Valid from <span className="text-danger">*</span></label>
                                                <input id="startDate" type="datetime-local" name="startDate" required data-msg="Please enter Start Date" className="form-control" onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                                <input id="expiryDate" type="date" name="expiryDate" required data-msg="Please enter Expire Date" className="form-control" onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6" >
                                            <div className="form-group">
                                                <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                <Select
                                                    isMulti
                                                    name="mode"
                                                    options={this.orderModes}
                                                    className="text-capitalize basic-multi-select mt-2"
                                                    classNamePrefix="select"
                                                    onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="form-group d-flex justify-content-end mt-4">
                                        <button className='btn btn-primary' disabled={!this.isDiscountReady()} onClick={() => this.handleSaveBtnClick(this.props.history)}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </Modal>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        brands: state.store.brands,
        menus: state.menu.menus,
        groups: state.menu.groupsptions,
        items: state.menu.allActiveItems,
        message: state.discount.message,
        isInserted: state.discount.isInserted
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addDiscount: function (data: any, history: any) {
            dispatch(addDiscount(data, history));
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        menusList: function () {
            dispatch(menusList())
        },
        groupsList: function () {
            dispatch(groupsListForMultiSelect())
        },
        itemsListForMultiSelect: function () {
            dispatch(itemsListForMultiSelect())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddDiscount);