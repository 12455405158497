import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import jwt from "jsonwebtoken";
import { connect } from "react-redux";
import { secretKey } from "../../../secret";
import { discountsList, logoutUser} from "../../../redux";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { Button, Modal, Container } from "react-bootstrap";
import "react-toggle/style.css";
import Api from "../../../components/Api";
import { DiscountProps } from "../../interface/discounts";
import Add_discount from "./add_discount";
import Edit_discount from "./edit_discount";

class Discounts extends Component<DiscountProps,{ discountModal: any; id:any;discountData:any;discounts:any }> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      discountModal: "",
      id : "",
      discountData:{},
      discounts:[]
    };
  }
  componentDidMount() {
    this.props.discountsList()
    document.title = "Discounts";
  }

  componentDidUpdate(prevProps:any) 
  {  
    if (this.props.data.length > prevProps.data.length) 
    {
        this.setState({ discounts: this.props.data });
    }
  }
  shouldComponentUpdate(nextProps:any) {
    return true;
  }
  setDiscountModal = (condition: any) => {
    this.setState({ discountModal: condition });
  };


  setEditDiscountId = (id:any) => {
    console.log("id",id)
    this.setState({id:id})
    let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/discount/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    this.setState({discountData:response.data.successResponse})
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
  }
  
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    return (
      <Fragment>
        {/* Add Modal */}
            <Add_discount
              setDiscountModal={async (e) => this.setDiscountModal(e)}
              discountModal={this.state.discountModal}
              onModalClose={undefined}
              onSave={undefined}
              columns={undefined}
              validateState={undefined}
              ignoreEditable={undefined} history={undefined}            
            />
        <Row>
          <Col xl={12}>
            <Card>
              <Card.Header>
                <Card.Title>Discounts</Card.Title>
                <Button
                  variant="primary"
                  className="p-2"
                  onClick={() => this.setDiscountModal(true)}
                  // style={{ marginLeft: "55rem" }}
                >
                  Add Discount
                </Button>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Tab.Container
                    defaultActiveKey={
                      this.props.data &&
                      this.props.data.length > 0 &&
                      this.props.data[0].discount_name.toLowerCase()
                    }
                  >
                    <Col xl={3}>
                      <Nav as="ul" className="flex-column nav-pills mb-3">
                        {this.props.data.map((data: any, i: any) => (
                          <Nav.Item as="li" key={i} 
                          onClick={()=>this.setEditDiscountId(data.discount_id)}
                          >
                            <Nav.Link eventKey={data.discount_name.toLowerCase()} >
                              {data.discount_name}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </Col>
                    <Col xl={9}>
                      <Tab.Content className="ml-2">
                        <Edit_discount 
                            discountData={this.state.discountData} 
                            match={undefined} 
                            id={this.state.id} 
                        />
                      </Tab.Content>{" "}
                    </Col>
                  </Tab.Container>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    data: state.discount.data
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    discountsList: function () {
      dispatch(discountsList())
  },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Discounts);
