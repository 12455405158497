import { RECIPES_LIST } from "../actions/recipeType";

const initialState: any = {
    data: [] || ""
}
const recipeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case RECIPES_LIST: return {
            ...state,
            data: action.payload
        }
        default: return state;
    }
}
export default recipeReducer;