import { DISCOUNT_LIST, GET_DISCOUNT, ADD_DISCOUNT, EDIT_DISCOUNT, GET_SUPPLIER } from '../actions/discountType'
const initialState: any = {
    data: [] || "",
    discountData: {},
    isInserted: "",
    isUpdated: "",
    message: "",
    supplier:{}
}

const couponReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DISCOUNT_LIST: return {
            ...state,
            data: action.payload
        }
        case GET_DISCOUNT: return {
            ...state,
            discountData: action.payload
        }
        case ADD_DISCOUNT: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case EDIT_DISCOUNT: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        case GET_SUPPLIER: return {
            ...state,
            supplier: action.payload
        }
        default: return state;
    }
}
export default couponReducer;