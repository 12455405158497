import { BUSINESS_TYPE_LIST } from '../actions/settingsType'
import { STORES_LIST, GET_STORE, ADD_STORE, BRANDS_LIST, CHANNEL_LIST, TYPES_LIST, AREAS_LIST, COUNTRY_LIST, EDIT_STORE, GET_BACKUP_STORES, STATUS_CHANGE_REASONS, UNRESOLVED_ORDER_LENGTH, CITIES, GET_TRADE_AREAS } from '../actions/storeType'
const initialState: any = {
    data: [] || "",
    storeData: {},
    isInserted: "",
    isUpdated: "",
    message: "",
    brands: [] || "",
    channels: [] || "",
    types: [] || "",
    areas: [] || "",
    countries: [] || "",
    businessType: [] || "",
    getBackupStores: [] || "",
    getTradeAreas: [] || "",
    statusChangeReasons: [] || "",
    unresolvedStoreOrder: [] || "",
    cities: [] || ""
}

const storeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case STORES_LIST: return {
            ...state,
            data: action.payload
        }
        case GET_STORE: return {
            ...state,
            storeData: action.payload
        }
        case ADD_STORE: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case BRANDS_LIST: return {
            ...state,
            brands: action.payload
        }
        case CHANNEL_LIST: return {
            ...state,
            channels: action.payload
        }
        case TYPES_LIST: return {
            ...state,
            types: action.payload
        }
        case AREAS_LIST: return {
            ...state,
            areas: action.payload
        }
        case COUNTRY_LIST: return {
            ...state,
            countries: action.payload
        }
        case BUSINESS_TYPE_LIST: return {
            ...state,
            businessType: action.payload
        }
        case EDIT_STORE: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        case GET_BACKUP_STORES: return {
            ...state,
            getBackupStores: action.payload
        }
        case GET_TRADE_AREAS: return {
            ...state,
            getTradeAreas: action.payload
        }
        case STATUS_CHANGE_REASONS: return {
            ...state,
            statusChangeReasons: action.payload
        }
        case UNRESOLVED_ORDER_LENGTH: return {
            ...state,
            unresolvedStoreOrder: action.payload
        }
        case CITIES: return {
            ...state,
            cities: action.payload
        }
        default: return state;
    }
}
export default storeReducer;