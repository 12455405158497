import { CHANNELS_LIST, CHILD_COMPANY_LIST, COMPANY_LIST, COUNT_LEVEL_LIST, CURRENCY_LIST, GET_CHANNEL, GET_CHILD_COMPANY, GET_COMPANY, GET_DOB, GET_ITEM_NUTRITION, GET_ITEM_UNIT_OF_MEASURES, GET_RECIPE, GET_SALE_FOR_RECONCILE, GET_UNIT_OF_MEASURE, GET_WORKFLOW, ITEM_CATEGORY_LIST, ITEM_TYPE_LIST, NUTRITION_INFO_LIST, NUTRITION_UNIT_LIST, RECIPE_CHANNEL_LIST, RECIPE_TYPE_LIST, RELIGIOUS_SUITABILITY_LIST, TASK_FREQUENCY_LIST, TIME_ZONES, UNIT_OF_MEASURES_LIST, WORKFLOW_LIST } from "../actions/unitOfMeasureType";

const initialState: any = {
    unitOfMeasure: {},
    unitOfMeasures: [] || "",
    itemTypes: [] || "",
    itemCategories: [] || "",
    religiousSuitabilities: [] || "",
    countLevels: [] || "",
    nutritions : [] || "",
    nutritionUnits : [] || "",
    recipeTypes : [] || "",
    recipeChannels: [] || "",
    itemUnitOfMeasures:[] || "",
    itemNutrition :{} ||"",
    recipe:{},
    channels: [] || "",
    channel:{},
    company:{},
    childCompanies:[] || "",
    childCompany : {},
    companies : [] || "",
    dob:{},
    timezones : [] || "",
    task_frequencies : [] || "",
    workflows : [] || "",
    workflow: {},
    currencies : [] || "",
    sales_for_reconcile : [] || ""
}
const unitOfMeasureReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_UNIT_OF_MEASURE: return {
            ...state,
            unitOfMeasure: action.payload
        }
        case UNIT_OF_MEASURES_LIST: return {
            ...state,
            unitOfMeasures: action.payload
        }
        case ITEM_TYPE_LIST: return {
            ...state,
            itemTypes: action.payload
        }
        case ITEM_CATEGORY_LIST: return {
            ...state,
            itemCategories: action.payload
        }
        case RELIGIOUS_SUITABILITY_LIST :return{
            ...state,
            religiousSuitabilities:action.payload
        }
        case COUNT_LEVEL_LIST: return {
            ...state,
            countLevels: action.payload
        }
        case NUTRITION_INFO_LIST : return {
            ...state,
            nutritions:action.payload
        }
        case NUTRITION_UNIT_LIST : return {
            ...state,
            nutritionUnits : action.payload
        }
        case RECIPE_TYPE_LIST: return {
            ...state,
            recipeTypes : action.payload
        }
        case RECIPE_CHANNEL_LIST: return {
            ...state,
            recipeChannels : action.payload
        }
        case GET_ITEM_UNIT_OF_MEASURES : return {
            ...state,
            itemUnitOfMeasures : action.payload
        }
        case GET_ITEM_NUTRITION : return {
            ...state,
            itemNutrition: action.payload
        }
        case GET_RECIPE: return{
            ...state,
            recipe: action.payload
        }
        case CHANNELS_LIST: return {
            ...state,
            channels: action.payload
        }
        case GET_CHANNEL: return {
            ...state,
            channel: action.payload
        }
        case GET_COMPANY: return {
            ...state,
            company: action.payload
        }
        case CHILD_COMPANY_LIST : return {
            ...state,
            childCompanies: action.payload
        }
        case GET_CHILD_COMPANY: return {
            ...state,
            childCompany: action.payload
        }
        case COMPANY_LIST: return {
            ...state,
            companies : action.payload
        }
        case GET_DOB : return {
            ...state,
            dob : action.payload
        }
        case TIME_ZONES : return {
            ...state,
            timezones : action.payload
        }
        case TASK_FREQUENCY_LIST : return {
            ...state,
            task_frequencies : action.payload
        }
        case WORKFLOW_LIST : return {
            ...state,
            workflows : action.payload
        }
        case GET_WORKFLOW : return {
            ...state,
            workflow : action.payload
        }
        case CURRENCY_LIST : return {
            ...state,
            currencies : action.payload
        }
        case GET_SALE_FOR_RECONCILE : return {
            ...state,
            sales_for_reconcile : action.payload
        }
        default: return state;
    }
}
export default unitOfMeasureReducer;