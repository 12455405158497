import React, { Component, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import jwt from "jsonwebtoken";
import { connect } from "react-redux";
import { secretKey } from "../../../secret";
import {
  logoutUser,
  modGroupList,
} from "../../../redux";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { Button, Modal, Container } from "react-bootstrap";
import "react-toggle/style.css";
import Api from "../../../components/Api";
import { ChoicesProps } from "../../interface/choice";
import AddModGroup from "../new-menu/modifiers/add-mod-group";
import EditModGroup from "../new-menu/modifiers/edit-mod-group";
class Choices extends Component<
ChoicesProps,
  { choiceModal: any; id:any;choiceData:any;choices:any; }
> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      choiceModal: "",
      id : "",
      choiceData:{},
      choices:[],
    };
  }
  componentDidMount() {
    this.props.modGroupList()
    document.title = "Menus";
  }
  
  setChoiceModal = (condition: any) => {
    this.setState({ choiceModal: condition });
  };

  shouldComponentUpdate(nextProps:any) {
    return true;
  }
  componentDidUpdate(prevProps:any) 
  {  
    if (this.props.data.length > prevProps.data.length)
    {
        // this.setState({ choices: this.props.data });
    }
  }

  setEditChoiceId = (id:any) => {
    console.log("id",id)
    this.setState({id:id})
    let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/choice/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    this.setState({choiceData:response.data.successResponse})
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
  }
  getItemId = () => {
    return this.state.id
  }
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    return (
      <Fragment>
        {/* Add Category Modal */}

            <AddModGroup
              setChoiceModal={async (e) => this.setChoiceModal(e)}
              choiceModal={this.state.choiceModal} 
              onModalClose={undefined} 
              onSave={undefined} 
              columns={undefined} 
              validateState={undefined} 
              ignoreEditable={undefined} 
              />


        <Row>
          <Col xl={12}>
            <Card>
              <Card.Header>
                <Card.Title>Choices</Card.Title>
                <Button
                  variant="primary"
                  className="p-2"
                  onClick={() => this.setChoiceModal(true)}
                  // style={{ marginLeft: "55rem" }}
                >
                  Add Choice
                </Button>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Tab.Container
                    defaultActiveKey={
                      this.props.data &&
                      this.props.data.length > 0 &&
                      this.props.data[0].mod_group_name.toLowerCase()
                    }
                  >
                    <Col xl={3}>
                      <Nav as="ul" className="flex-column nav-pills mb-3">
                        {this.props.data.map((data: any, i: any) => (
                          <Nav.Item as="li" key={i} onClick={()=>this.setEditChoiceId(data.mod_group_id)}>
                            <Nav.Link eventKey={data.mod_group_name.toLowerCase()} >
                              {data.mod_group_name}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </Col>
                    <Col xl={9}>
                      <Tab.Content className="ml-2">
                        <EditModGroup modGroupData={this.state.choiceData} match={undefined} id={this.state.id}/>
                      </Tab.Content>{" "}
                    </Col>
                  </Tab.Container>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    data: state.menu.modgroups
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    modGroupList: function () {
      dispatch(modGroupList())
  },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Choices);
