import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import CustomInsertModal from './add-role'
import jwt from 'jsonwebtoken'
import { connect } from 'react-redux'
import { StatesProps } from '../../../interface/settings';
import { secretKey } from '../../../../secret';
import { delState, logoutUser, statesList } from '../../../../redux';

class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any) => {
        this.props.data.delete(id,this.props.row.state_name)
    };
    render() {
        const { row } = this.props
        return (
            <div>
                <button title="Delete State" className="btn btn-outline-danger" data-toggle="modal" data-target={`#DelRole${row.state_id}`}><i className="fa fa-trash"></i></button>
                <Link title="Edit State" className="btn btn-outline-primary ml-2" to={`/edit-region/${row.state_id}`}><i className="fa fa-edit"></i></Link>
                {/* <!-- Modal--> */}
                <div id={`DelRole${row.state_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete State</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to delete this state?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button onClick={() => this.handleDelete(row.state_id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}
class States extends Component<StatesProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.statesList();
        document.title = "Regions"
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let tax: any = []
        // let tokendata: any = jwt.decode(sessionStorage.token)
        // let roleId = tokendata.role_id;
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-region" /> },
            noDataText: 'States Not Found'
        };
        return (
            <div className="page">
                {/* <Topbar /> */}
                <div className="page-content">
                    {/* <Sidebar /> */}
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Regions Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable data={this.props.states} tableStyle={{alignSelf:"center"}} bordered={false} search={true} pagination={tax.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='tax.csv' hover condensed={true}>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='state_id' csvHeader='#' width='50' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='country_name' csvHeader='Country' width='100' columnTitle>Country</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='state_name' csvHeader='State' width='100' columnTitle>Region</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='payment_method' csvHeader='Payment' width='100' columnTitle>Payment Method</TableHeaderColumn>
                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='tax_type' dataFormat={taxTypeFormatter} csvHeader='Type' width='130' columnTitle>Tax Type</TableHeaderColumn> */}
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='tax_percent' dataFormat={taxFormatter} csvHeader='Tax' width='120' columnTitle>Tax</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='130' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                        {/* <Footer /> */}
                    </div>
                </div>
            </div>
        );
    }
}
;
function taxFormatter(cell: any) {
    return `${cell} %`;
}
function taxTypeFormatter(cell: any) {
    return `${cell==0?"Inclusive tax":"Exclusive tax"}`;
}
const mapStateToProps = (state: any) => {
    return {
        states: state.setting.states
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        statesList: function () {
            dispatch(statesList())
        },
        delete:(id:any,state_name:any)=>{
            dispatch(delState(id,state_name))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(States);