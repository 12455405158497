import React from "react";
import { Bar } from "react-chartjs-2";
// import PropTypes from "prop-types";

const BarChartAnalytics = ({ salesData }) => {
  if (!Array.isArray(salesData) || salesData.length === 0) {
    return <div>Loading...</div>;
  }

  const labels = salesData.map((data) => data.month);
  const sales = salesData.map((data) => Math.round(data.sales));

  const data = {
    defaultFontFamily: "Poppins",
    labels: labels,
    datasets: [
      {
        data: sales,
        borderColor: "rgba(0, 0, 0, 250)",
        borderWidth: "0",
        backgroundColor: "rgba(221, 47, 110, 0.5)",
        hoverBackgroundColor: "rgba(221, 47, 110, 1)",
      },
    ],
  };

  const options = {
    legend: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          // Change here
          barPercentage: 0.5,
        },
      ],
    },
  };

  return (
    <>
      <Bar data={data} height={90} options={options} />
    </>
  );
};

// BarChartAnalytics.propTypes = {
//   salesData: PropTypes.arrayOf(
//     PropTypes.shape({
//       month: PropTypes.string.isRequired,
//       sales: PropTypes.number.isRequired,
//     })
//   ).isRequired,
// };

export default BarChartAnalytics;
