import React, { Component } from "react";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { Link, Redirect } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AddMenuItemProps, AddMenuItemState } from "../../../interface/newMenu";
import { secretKey } from "../../../../secret";
import {
  addItems,
  checkDuplicateErpId,
  checkDuplicatePosCode,
  groupsList,
  logoutUser,
  menusList,
  statesList,
  storesListForMultiSelect,
} from "../../../../redux";
import {
  allergensList,
  OrderingModes,
} from "../../../../redux/actions/menuAction";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { Row, Col, Card, Tab, Nav, Modal, Button } from "react-bootstrap";
toast.configure();
class AddMenuItem extends Component<AddMenuItemProps, AddMenuItemState> {
  constructor(props: any) {
    super(props);
    this.state = {
      itemname: "",
      group: "",
      type: "",
      priority: "",
      images: null,
      hero_image: null,
      hero_mobile_image: null,
      hero_item: false,
      settime: "alltime",
      tax_percent: "",
      itemstart: "",
      itemclose: "",
      state: "",
      mode: [{ label: "Online", value: 1 }],
      is_lsm: "0",
      storesJson: [],
      metaTitle: "",
      metaDesc: "",
      is_featured: false,
      is_alcohol: false,
      is_halal:false,
      is_veg:false,
      is_vegan:false,
      selectedTab: "one",
      sizejson: [
        {
          pos_code: "",
          erp_id: "",
          aloha_cat_id: "",
          size: "Regular",
          cost: 0,
          mrp: "",
          extra_price: "",
          description: "",
          alt_text: "",
          image_url: null,
          order_modes_price: [
            {
              extra_price: 0,
              item_variant_id: undefined,
              label: "Online",
              mrp: 0,
              order_mode: "Online",
              order_mode_id: 1,
              value: 1,
            },
            // {
            //     extra_price: 0 ,
            //     item_variant_id: undefined,
            //     label : "Mobile",
            //     mrp : 0,
            //     order_mode : "Mobile",
            //     order_mode_id : 2,
            //     value : 2
            // }
          ],
        },
      ],
      banner_title: "",
      is_banner: "",
      banner_description: "",
      allergens: "",
    };
    this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleItemsByGroupId = this.handleItemsByGroupId.bind(this);
  }
  componentDidMount() {
    this.props.menusList();
    this.props.groupsList();
    this.props.storesList();
    this.props.OrderingModes();
    this.props.allergensList();
    this.setState({ group: this.props.add_group_id });
    document.title = "Menu Items";
  }
  static getDerivedStateFromProps(props: any, state: any) {
    if (props.add_group_id !== state.group) {
      // Return the updated state based on the prop change
      return { group: props.add_group_id };
    }
    return null;
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleItemsByGroupId(event: { target: { name: any; value: any } }) {
    this.setState({
      group: event.target.value,
      sizejson: [
        {
          pos_code: "",
          erp_id: "",
          aloha_cat_id: "",
          size: "",
          cost: "",
          mrp: "",
          extra_price: "",
          description: "",
          alt_text: "",
          image_url: null,
          order_modes_price: [
            {
              extra_price: 0,
              item_variant_id: undefined,
              label: "Online",
              mrp: 0,
              order_mode: "Online",
              order_mode_id: 1,
              value: 1,
            },
            // {
            //     extra_price: 0 ,
            //     item_variant_id: undefined,
            //     label : "Mobile",
            //     mrp : 0,
            //     order_mode : "Mobile",
            //     order_mode_id : 2,
            //     value : 2
            // }
          ],
        },
      ],
    });
  }
  handleTabNext = () => {
    const tabCount = 5; // Total number of tabs
    const currentIndex = this.getTabIndex(this.state.selectedTab);
    const nextIndex = (currentIndex + 1) % tabCount;
    const nextTab = this.getTabKey(nextIndex);
    this.setState({ selectedTab: nextTab });
  };
  handleTabBack = () => {
    const tabCount = 5; // Total number of tabs
    const currentIndex = this.getTabIndex(this.state.selectedTab);
    const prevIndex = (currentIndex + tabCount - 1) % tabCount;
    const prevTab = this.getTabKey(prevIndex);
    this.setState({ selectedTab: prevTab });
  };

  getTabIndex = (tabKey: any) => {
    const tabs = ["one", "two", "three", "four", "five"];
    return tabs.indexOf(tabKey);
  };

  getTabKey = (index: any) => {
    const tabs = ["one", "two", "three", "four", "five"];
    return tabs[index];
  };

  renderButtons() {
    const { selectedTab } = this.state;
    const isFirstTab = selectedTab === "one";
    const isLastTab = selectedTab === "five";

    if (isLastTab) {
      return (
        <>
          <Button
            variant="danger light"
            onClick={() => this.props.setItemModal(false)}
          >
            Cancel
          </Button>
          <button className="btn btn-primary" onClick={this.handleSaveBtnClick}>
            Add Product
          </button>
        </>
      );
    } else {
      return (
        <>
          <Button
            variant="danger light"
            onClick={this.handleTabBack}
            disabled={isFirstTab}
          >
            Back
          </Button>
          <button className="btn btn-primary" onClick={this.handleTabNext}>
            Next
          </button>
        </>
      );
    }
  }

  // handle click event of the Time Radio buttons
  handleChangeRad = (event: { target: { name: any; value: any } }) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleVariantInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = this.state.sizejson;
    list[index][name] = value;
    this.setState({ sizejson: list });
    if (name == "pos_code") {
      this.props.checkDuplicatePosCode(value);
    }
    if (name == "erp_id") {
      this.props.checkDuplicateErpId(value);
    }
  };

  // handle click event of the Remove button
  handleRemoveClick = (index: any) => {
    const list = this.state.sizejson;
    list.splice(index, 1);
    this.setState({ sizejson: list });
  };
  // handle click event of the Add button
  handleAddClick = (i: any) => {
    const list: any = this.state.sizejson;
    let { duplicateItemErp, duplicateItemPos } = this.props;
    if (list[i].order_modes_price[list[i].order_modes_price.length - 1]) {
      if (
        list[i]["size"] == "" ||
        list[i]["cost"] == "" ||
        (list[i]["pos_code"] !== "" && duplicateItemPos == true) ||
        (list[i]["erp_id"] !== "" && duplicateItemErp == true) ||
        // list[i]["image_url"] == null ||
        list[i].order_modes_price[list[i].order_modes_price.length - 1].mrp == 0
      ) {
        alert("Please fill all mandatory variant fields");
      } else if (i == "3") {
        alert("Sorry you can select only 4 variants");
      } else {
        const newlist: any = {
          pos_code: "",
          erp_id: "",
          size: "",
          cost: "",
          mrp: "",
          extra_price: "",
          description: "",
          image_url: null,
          order_modes_price: [],
        };
        this.state.mode &&
          this.state.mode.forEach((elem) => {
            let mode_id = elem.value;
            let mode_label = elem.label;
            let obj = {
              item_variant_id: "",
              order_mode_id: mode_id,
              mrp: 0,
              extra_price: 0,
              label: mode_label,
              value: mode_id,
            };
            newlist.order_modes_price.push(obj);
          });
        const menu_items: any = this.state.sizejson.concat(newlist);
        this.setState({ sizejson: menu_items });
      }
    } else {
      alert("Please select at least one ordering mode!");
    }
  };
  fileSelectedHandler = (e: any, index: any) => {
    const list: any = this.state.sizejson;
    if (e.target.files.length > 0) {
      list[index]["image_url"] = e.target.files;
      this.setState({ sizejson: list });
    }
  };
  handleOrderModesInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      if (e.length < this.state.mode.length) {
        let tempsizeJson = this.state.sizejson;
        for (let i = 0; i < tempsizeJson.length; i++) {
          let tempArr: any[] = tempsizeJson[i].order_modes_price;
          let selectedItems = tempArr.filter((obj) =>
            e.find((s: any) => s.value === obj.value)
          );
          tempsizeJson[i].order_modes_price = selectedItems;
        }
        console.log("tempsizeJson", tempsizeJson);
        this.setState({ sizejson: tempsizeJson });
      } else {
        let tempsizeJson = this.state.sizejson;
        for (let i = 0; i < tempsizeJson.length; i++) {
          let item_variant_id = this.state.sizejson[i].id;
          let mode_id =
            e[this.state.sizejson[i].order_modes_price.length].value;
          let mode_label =
            e[this.state.sizejson[i].order_modes_price.length].label;
          let mrp = this.state.sizejson[i].mrp;
          let extra_price = this.state.sizejson[i].extra_price;
          let obj = {
            item_variant_id: item_variant_id,
            order_mode_id: mode_id,
            order_mode: mode_label,
            mrp: mrp ? mrp : 0,
            extra_price: extra_price ? extra_price : 0,
            label: mode_label,
            value: mode_id,
          };

          tempsizeJson[i].order_modes_price.push(obj);
        }
        console.log("tempsizeJson else", tempsizeJson);
        this.setState({ sizejson: tempsizeJson });
      }
      console.log("mode", e);
      this.setState({ mode: e });
    } else {
      this.setState({ mode: [] });
      let tempsizeJson = this.state.sizejson;
      for (let i = 0; i < tempsizeJson.length; i++) {
        tempsizeJson[i].order_modes_price = [];
      }
      console.log("tempsizeJson", tempsizeJson);
      this.setState({ sizejson: tempsizeJson });
    }
  };
  handleVariantPriceInputChange = (e: any, index: any, sizeJsonindex: any) => {
    const { name, value } = e.target;
    const list: any = this.state.sizejson;
    list[sizeJsonindex].order_modes_price[index][name] = value;
    this.setState({ sizejson: list });
  };
  handlePricingOrderModesInputChange = (e: any, indexing: any, ind: any) => {
    if (e && e.length > 0) {
      if (e.length === this.state.sizejson[ind].order_modes_price.length + 1) {
        if (this.state.sizejson[ind].order_modes_price.length == 0) {
          let mode_id =
            e[this.state.sizejson[ind].order_modes_price.length].value;
          let mode_label =
            e[this.state.sizejson[ind].order_modes_price.length].label;
          // this.setState({ mode: e });
          let tempsizeJson = this.state.sizejson;
          let obj = {
            item_variant_id: "",
            order_mode_id: mode_id,
            mrp: 0,
            extra_price: 0,
            label: mode_label,
            value: mode_id,
          };
          tempsizeJson[ind].order_modes_price.push(obj);
          this.setState({ sizejson: tempsizeJson });
        } else {
          if (
            this.state.sizejson[ind].order_modes_price[
              this.state.sizejson[ind].order_modes_price.length - 1
            ].mrp > 0
          ) {
            let mode_id =
              e[this.state.sizejson[ind].order_modes_price.length].value;
            let mode_label =
              e[this.state.sizejson[ind].order_modes_price.length].label;
            // this.setState({ mode: e });
            let tempsizeJson = this.state.sizejson;
            let obj = {
              item_variant_id: "",
              order_mode_id: mode_id,
              mrp: 0,
              extra_price: 0,
              label: mode_label,
              value: mode_id,
            };
            tempsizeJson[ind].order_modes_price.push(obj);
            this.setState({ sizejson: tempsizeJson });
          } else {
            toast.error("Max retail price should not be 0,Please update", {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
        }
      } else {
        console.log(indexing);
        let tempsizeJson = this.state.sizejson;
        let dubtempsizeJson = tempsizeJson[ind].order_modes_price.filter(
          (item: any) => item.order_mode_id != indexing.removedValue.value
        );
        tempsizeJson[ind].order_modes_price = dubtempsizeJson;
        this.setState({ sizejson: tempsizeJson });
      }
    } else {
      let tempsizeJson = this.state.sizejson;
      tempsizeJson[ind].order_modes_price = [];
      this.setState({ sizejson: tempsizeJson });
    }
  };

  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ storesJson: e });
    } else {
      this.setState({ storesJson: [] });
    }
  };
  handleHeroItem = (event: { target: { name: any; value: any } }) => {
    const { hero_item } = this.state;
    this.setState({ hero_item: !hero_item });
  };

  handleFeatured = (event: { target: { name: any; value: any } }) => {
    const { is_featured } = this.state;
    this.setState({ is_featured: !is_featured });
  };
  handleAlcohol = (event: { target: { name: any; value: any } }) => {
    const { is_alcohol } = this.state;
    this.setState({ is_alcohol: !is_alcohol });
  };

  handleHalal = (event: { target: { name: any; value: any } }) => {
    const { is_halal } = this.state;
    this.setState({ is_halal: !is_halal });
  };
  handleVeg = (event: { target: { name: any; value: any } }) => {
    const { is_veg } = this.state;
    this.setState({ is_veg: !is_veg });
  };
  handleVegan = (event: { target: { name: any; value: any } }) => {
    const { is_vegan } = this.state;
    this.setState({ is_vegan: !is_vegan });
  };

  heroItemImageSelectedHandler = (e: any) => {
    if (e.target.files.length > 0) {
      this.setState({ hero_image: e.target.files });
    }
  };
  heroItemMobileImageSelectedHandler = (e: any) => {
    if (e.target.files.length > 0) {
      this.setState({ hero_mobile_image: e.target.files });
    }
  };
  checkMRPForAllModes = () => {
    let tempsizeJson = this.state.sizejson;
    let variants: any = [];
    for (let i = 0; i < tempsizeJson.length; i++) {
      let order_modes_price: any[] = tempsizeJson[i].order_modes_price;
      var filtered =
        order_modes_price &&
        order_modes_price.filter(function (el) {
          return el.mrp == 0 || el.mrp == "";
        });
      if (filtered.length > 0) {
        variants.push(tempsizeJson[i]);
      }
    }
    if (variants.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  isMenuItemReady = () => {
    const {
      itemname,
      group,
      settime,
      itemstart,
      itemclose,
      hero_item,
      mode,
      hero_image,
      is_lsm,
      storesJson,
    } = this.state;
    let { duplicateItemPos, duplicateItemErp } = this.props;
    if (settime == "customtime") {
      if (hero_item == false) {
        if (is_lsm == "0") {
          return (
            itemname !== "" &&
            group !== "" &&
            itemstart !== "" &&
            itemclose !== "" &&
            mode.length > 0 &&
            duplicateItemPos !== true &&
            duplicateItemErp !== true &&
            this.checkMRPForAllModes()
          );
        } else if (is_lsm == "1") {
          return (
            itemname !== "" &&
            group !== "" &&
            itemstart !== "" &&
            itemclose !== "" &&
            storesJson.length > 0 &&
            mode.length > 0 &&
            duplicateItemPos !== true &&
            duplicateItemErp !== true &&
            this.checkMRPForAllModes()
          );
        }
      } else {
        if (is_lsm == "0") {
          return (
            itemname !== "" &&
            group !== "" &&
            hero_image !== null &&
            itemstart !== "" &&
            itemclose !== "" &&
            mode.length > 0 &&
            duplicateItemPos !== true &&
            duplicateItemErp !== true &&
            this.checkMRPForAllModes()
          );
        } else if (is_lsm == "1") {
          return (
            itemname !== "" &&
            group !== "" &&
            hero_image !== null &&
            itemstart !== "" &&
            itemclose !== "" &&
            storesJson.length > 0 &&
            mode.length > 0 &&
            duplicateItemPos !== true &&
            duplicateItemErp !== true &&
            this.checkMRPForAllModes()
          );
        }
      }
    } else {
      if (hero_item == false) {
        if (is_lsm == "0") {
          return (
            itemname !== "" &&
            group !== "" &&
            mode.length > 0 &&
            duplicateItemPos !== true &&
            duplicateItemErp !== true &&
            this.checkMRPForAllModes()
          );
        } else if (is_lsm == "1") {
          return (
            itemname !== "" &&
            group !== "" &&
            storesJson.length > 0 &&
            mode.length > 0 &&
            duplicateItemPos !== true &&
            duplicateItemErp !== true &&
            this.checkMRPForAllModes()
          );
        }
      } else {
        if (is_lsm == "0") {
          return (
            itemname !== "" &&
            group !== "" &&
            hero_image !== null &&
            mode.length > 0 &&
            duplicateItemPos !== true &&
            duplicateItemErp !== true &&
            this.checkMRPForAllModes()
          );
        } else if (is_lsm == "1") {
          return (
            itemname !== "" &&
            group !== "" &&
            hero_image !== null &&
            storesJson.length > 0 &&
            mode.length > 0 &&
            duplicateItemPos !== true &&
            duplicateItemErp !== true &&
            this.checkMRPForAllModes()
          );
        }
      }
    }
  };
  isUnique = (arr: any) => {
    const len = arr.length;
    for (let i = 0; i < len; i++) {
      for (let j = 0; j < len; j++) {
        // if the elements match, this wouldn't be a unique array
        if (
          (arr[i].pos_code !== "" && arr[j].pos_code !== "") ||
          (arr[i].erp_id !== "" && arr[j].erp_id !== "")
        ) {
          if (
            i !== j &&
            (arr[i].pos_code === arr[j].pos_code ||
              arr[i].erp_id === arr[j].erp_id)
          ) {
            return false;
          }
        }
      }
    }
    return true;
  };
  blockSpecialCharacters = () => {
    var checkString = this.state.itemname;
    if (checkString != "") {
      if (/[%]/.test(checkString)) {
        alert(
          "Your item name has '%' sign. \nIt is not allowed.\nPlease remove it and try again."
        );
        return false;
      } else {
        return true;
      }
    }
  };
  handleSaveBtnClick = (event: any) => {
    let {
      itemname,
      group,
      type,
      is_featured,
      priority,
      mode,
      hero_item,
      hero_image,
      hero_mobile_image,
      sizejson,
      settime,
      itemstart,
      itemclose,
      is_lsm,
      storesJson,
      metaTitle,
      metaDesc,
      allergens,
      is_alcohol,
      is_halal,
      is_veg,
      is_vegan,
      banner_title,
      banner_description,
    } = this.state;
    const data: any = new FormData();
    let itemNamevalid = this.blockSpecialCharacters();
    if (itemNamevalid) {
      if (this.state.sizejson) {
        for (var x = 0; x < this.state.sizejson.length; x++) {
          if (this.state.sizejson[x].image_url !== null) {
            var file = this.state.sizejson[x].image_url[0];
            var newFileName =
              file.name.split(".")[0] +
              `_variant_${x}.` +
              file.name.split(".")[1];
            data.append("files", file, newFileName);
          }
        }
      }
      const SizeJsonString = JSON.stringify(sizejson);
      if (hero_image) {
        var file = hero_image[0];
        var newFileName =
          file.name.split(".")[0] + "_hero_image." + file.name.split(".")[1];
        data.append("files", file, newFileName);
      }
      if (hero_mobile_image) {
        var file = hero_mobile_image[0];
        var newFileName =
          file.name.split(".")[0] +
          "_hero_mobile_image." +
          file.name.split(".")[1];
        data.append("files", file, newFileName);
      }
      data.append("item_name", itemname);
      // if (erpid !== null) {
      //     data.append('erp_id', erpid)
      // }
      // if (poscode !== null) {
      //     data.append('pos_code', poscode)
      // }
      if (hero_item === true) {
        data.append("hero_item", 1);
        data.append("banner_title", banner_title);
        data.append("banner_description", banner_description);
      }
      if (is_lsm == "0") {
        data.append("is_lsm", 0);
      } else if (is_lsm == "1") {
        data.append("is_lsm", 1);
        data.append("stores_json", JSON.stringify(storesJson));
      }
      data.append("item_group_id", group);
      data.append("item_mode", JSON.stringify(mode));
      data.append("item_type", type);
      data.append("priority", priority);
      data.append("meta_title", metaTitle);
      data.append("meta_description", metaDesc);
      data.append("is_featured", is_featured ? 1 : 0);
      data.append("allergens", JSON.stringify(allergens));
      data.append("is_alcohol", is_alcohol ? 1 : 0);
      data.append("is_halal", is_halal ? 1 : 0);
      data.append("is_veg", is_veg ? 1 : 0);
      data.append("is_vegan", is_vegan ? 1 : 0);

      data.append("is_publish", 1);

      if (settime === "customtime") {
        data.append("item_start_time", moment(itemstart).utc(false));
        data.append("item_close_time", moment(itemclose).utc(false));
      }
      // if (sizejson[sizejson.length - 1]["image_url"] !== null && (sizejson[sizejson.length - 1].order_modes_price.length > 0 && sizejson[sizejson.length - 1].order_modes_price[sizejson[sizejson.length - 1].order_modes_price.length - 1].mrp > 0)) {
      if (
        sizejson[sizejson.length - 1].order_modes_price.length > 0 &&
        sizejson[sizejson.length - 1].order_modes_price[
          sizejson[sizejson.length - 1].order_modes_price.length - 1
        ].mrp > 0
      ) {
        if (this.isUnique(sizejson)) {
          data.append("itemSizes", SizeJsonString);
          this.props.addItems(data);
          this.props.setItemModal(false);
        } else {
          toast.error("Please fill unique POS & ERP codes", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      } else {
        alert("Please fill all mandatory variant fields");
      }
    }
  };
  handleAllergenInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ allergens: e });
    } else {
      this.setState({ allergens: [] });
    }
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    const { isInserted, message, groups } = this.props;
    const { sizejson, settime, hero_item, is_lsm } = this.state;
    if (!isInserted) {
      msg = message;
      messcolor = "red";
    }
    return (
      <>
        <Modal
          className="fade bd-example-modal-lg"
          show={this.props.itemModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>Add New Product</Modal.Title>
            <Button
              variant=""
              className="close"
              onClick={() => this.props.setItemModal(false)}
            >
              <span>&times;</span>
            </Button>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col xl={12}>
                <Card>
                  <Card.Header style={{ margin: "auto" }}>
                    <Card.Title>
                      <div className="container">
                        <div className="row">
                          {/* <input id="checkboxCustom2" type="checkbox" checked={this.state.is_featured} onChange={this.handleFeatured} className="checkbox-template" /> */}
                          <Toggle
                            className="custom-classname"
                            checked={this.state.is_featured}
                            onChange={this.handleFeatured}
                            icons={false}
                          />
                          <label
                            className="ml-2 mb-1"
                            htmlFor="checkboxCustom2"
                          >
                            Feature Product
                          </label>
                        </div>
                      </div>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Tab.Container activeKey={`${this.state.selectedTab}`}>
                      <Nav as="ul" className="nav-pills mb-4 light">
                        <Nav.Item
                          as="li"
                          key={1}
                          onClick={() => this.setState({ selectedTab: "one" })}
                        >
                          <Nav.Link eventKey={"one"}>Info</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          key={2}
                          onClick={() => this.setState({ selectedTab: "two" })}
                        >
                          <Nav.Link eventKey={"two"}>Price</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          key={3}
                          onClick={() =>
                            this.setState({ selectedTab: "three" })
                          }
                        >
                          <Nav.Link eventKey={"three"}>Allergens</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          key={4}
                          onClick={() => this.setState({ selectedTab: "four" })}
                        >
                          <Nav.Link eventKey={"four"}>Image</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          key={5}
                          onClick={() => this.setState({ selectedTab: "five" })}
                        >
                          <Nav.Link eventKey={"five"}>Banner</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content className="pt-4">
                        <Tab.Pane eventKey={"one"} key={1}>
                          <div className="row">
                            <div className="col-lg-8 col-sm-4 col-4">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Product name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="itemname"
                                  type="text"
                                  name="itemname"
                                  required
                                  data-msg="Please enter Item Name"
                                  className="form-control"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-4 col-12">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Priority
                                </label>
                                <input
                                  id="priority"
                                  type="text"
                                  name="priority"
                                  required
                                  data-msg="Please enter Priority"
                                  className="form-control"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Product Category{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="group"
                                  className="form-control text-capitalize mt-2"
                                  required
                                  data-msg="Please select Group"
                                  onChange={this.handleItemsByGroupId}
                                >
                                  <option>Select Category</option>
                                  {groups &&
                                    groups.map(
                                      (group, index) =>
                                        group && (
                                          <option
                                            key={index}
                                            {...(this.props.add_group_id &&
                                              this.props.add_group_id ===
                                                group.group_id && {
                                                selected: true,
                                              })}
                                            value={group.group_id}
                                          >
                                            {group.group_name}
                                          </option>
                                        )
                                    )}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                {sizejson.map((x: any, ind: any) => {
                                  return (
                                    <div key={ind}>
                                      <div className="row">
                                        <div className="col-lg-12 col-sm-6 col-6">
                                          <div className="form-group">
                                            <label className="form-control-label mb-3">
                                              Description
                                            </label>
                                            <input
                                              id="description"
                                              type="text"
                                              name="description"
                                              value={x.description}
                                              className="form-control"
                                              onChange={(e) =>
                                                this.handleVariantInputChange(
                                                  e,
                                                  ind
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="col-lg-12 col-sm-6 col-12">
                              <div className="form-group">
                                <label className="form-control-label mb-3">
                                  Tags{" "}
                                </label>
                              </div>
                              
                              <div className="row">
                                <div className="col-5">
                                <input
                                  id="checkboxCustom1"
                                  type="checkbox"
                                  name="taxstatus"
                                  checked={this.state.is_alcohol}
                                  onChange={this.handleAlcohol}
                                  className="checkbox-template"
                                />
                                <label
                                  className="ml-2"
                                  htmlFor="checkboxCustom1"
                                >
                                  Product contains alcohol
                                </label>
                                </div>
                                <div className="col-2">
                                  <input
                                    id="checkboxCustom1"
                                    type="checkbox"
                                    name="is_halal"
                                    checked={this.state.is_halal}
                                    onChange={this.handleHalal}
                                    className="checkbox-template"
                                  />
                                  <label
                                    className="ml-2"
                                    htmlFor="checkboxCustom1"
                                  >
                                    Halal
                                  </label>
                                </div>
                                <div className="col-2">
                                <input
                                  id="checkboxCustom1"
                                  type="checkbox"
                                  name="is_veg"
                                  checked={this.state.is_veg}
                                  onChange={this.handleVeg}
                                  className="checkbox-template"
                                />
                                <label
                                  className="ml-2"
                                  htmlFor="checkboxCustom1"
                                >
                                  Veg
                                </label>
                                </div>
                                
                                <div className="col-3">
                                <input
                                  id="checkboxCustom1"
                                  type="checkbox"
                                  name="is_vegan"
                                  checked={this.state.is_vegan}
                                  onChange={this.handleVegan}
                                  className="checkbox-template"
                                />
                                <label
                                  className="ml-2"
                                  htmlFor="checkboxCustom1"
                                >
                                  Vegan
                                </label>
                                </div>
                                
                              </div>

                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={"two"} key={2}>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                {sizejson.map((x: any, ind: any) => {
                                  return (
                                    <div key={ind}>
                                      <div className="row">
                                        {x.order_modes_price.length > 0 &&
                                          x.order_modes_price.map(
                                            (channel: any, index: any) => (
                                              <>
                                                {/* <div className="col-12 mb-2">
                                                                        <strong>{index + 1} -</strong>
                                                                        <strong style={{ fontSize: '16px' }} className="ml-2">
                                                                            {channel.label}
                                                                        </strong>
                                                                    </div> */}
                                                <div className="col-12">
                                                  <div className="form-group">
                                                    <label className="form-control-label mb-3">
                                                      Max Retail Price
                                                      <span className="text-danger">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      name="mrp"
                                                      type="number"
                                                      value={channel.mrp}
                                                      data-msg="Please enter mrp"
                                                      className="form-control"
                                                      onChange={(e) =>
                                                        this.handleVariantPriceInputChange(
                                                          e,
                                                          index,
                                                          ind
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={"three"} key={3}>
                          <label className="form-control-label">
                            Select allergens for item{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            isMulti
                            name="stores"
                            options={this.props.allergens}
                            className="text-capitalize basic-multi-select mt-2"
                            classNamePrefix="select"
                            onChange={(e, i) =>
                              this.handleAllergenInputChange(e, i)
                            }
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey={"four"} key={4}>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                {sizejson.map((x: any, index: any) => {
                                  return (
                                    <div key={index}>
                                      <div className="row">
                                        <div className="col-lg-12 col-sm-6 col-6">
                                          <div className="form-group">
                                            <label className="form-control-label mb-3">
                                              Item Image{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              id="files"
                                              type="file"
                                              name="files"
                                              className="form-control-file"
                                              onChange={(e) =>
                                                this.fileSelectedHandler(
                                                  e,
                                                  index
                                                )
                                              }
                                            />
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            >
                                              Image size should be less than 1
                                              mb
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={"five"} key={5}>
                          <div className="row">
                            <div className="col-lg-6 col-sm-6 col-6">
                              <div>
                                <input
                                  id="checkboxCustom1"
                                  type="checkbox"
                                  name="taxstatus"
                                  checked={hero_item}
                                  onChange={this.handleHeroItem}
                                  className="checkbox-template"
                                />
                                <label
                                  className="ml-2"
                                  htmlFor="checkboxCustom1"
                                >
                                  Item Banner
                                </label>
                              </div>
                            </div>
                          </div>
                          {hero_item && (
                            <div className="row">
                              <div className="col-12 mt-2">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Banner Title{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="itemname"
                                    maxLength={30}
                                    type="text"
                                    name="banner_title"
                                    required
                                    data-msg="Please enter banner title"
                                    className="form-control"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Banner Description{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="itemname"
                                    maxLength={60}
                                    type="text"
                                    name="banner_description"
                                    required
                                    data-msg="Please enter banner description"
                                    className="form-control"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                              {/* <div className="col-6 mt-2">
                                            <div className="form-group">
                                                <label className="form-control-label mb-3">Web Banner <span className="text-danger">*</span></label>
                                                <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemImageSelectedHandler} />
                                                <p style={{color:"red",fontSize:"12px"}}>Image size should be less than 1 mb</p>
                                            </div>
                                        </div>
                                        <div className="col-6 mt-2">
                                            <div className="form-group">
                                                <label className="form-control-label mb-3">App Banner</label>
                                                <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemMobileImageSelectedHandler} />
                                                <p style={{color:"red",fontSize:"12px"}}>Image size should be less than 1 mb</p>
                                            </div>
                                        </div> */}
                            </div>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {this.renderButtons()}
            {/* <Button
              variant="danger light"
              onClick={() => this.props.setItemModal(false)}
            >
              Close
            </Button> */}
            {/* <Button variant="" type="button" className="btn btn-primary">
              Save Item
            </Button> */}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    groups: state.menu.groups,
    message: state.menu.message,
    states: state.setting.states,
    menus: state.menu.menus,
    stores: state.menu.storesoptions,
    isInserted: state.menu.isInserted,
    duplicateItemPos: state.menu.duplicateItemPos,
    duplicateItemErp: state.menu.duplicateItemErp,
    orderingModes: state.menu.orderModes,
    allergens: state.menu.allergens,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    addItems: function (formData: any) {
      dispatch(addItems(formData));
    },
    checkDuplicatePosCode: (pos_code: any) => {
      dispatch(checkDuplicatePosCode(pos_code));
    },
    checkDuplicateErpId: (erp_id: any) => {
      dispatch(checkDuplicateErpId(erp_id));
    },
    groupsList: function () {
      dispatch(groupsList());
    },
    menusList: function () {
      dispatch(menusList());
    },
    statesList: function () {
      dispatch(statesList());
    },
    storesList: () => {
      dispatch(storesListForMultiSelect());
    },
    OrderingModes: () => {
      dispatch(OrderingModes());
    },
    allergensList: () => {
      dispatch(allergensList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddMenuItem);
