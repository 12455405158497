import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import Select from 'react-select';
import { EditModProps, EditModState } from '../../../interface/menu';
import { secretKey } from '../../../../secret';
import { editModifier, getModifier, logoutUser, modGroupsList } from '../../../../redux';

class EditMod extends Component<EditModProps, EditModState> {
    constructor(props: any) {
        super(props);
        this.state = {
            modGroupJson: [],
            modifiername: "",
            erpid: "",
            poscode: "",
            priority: "",
            modifiertype: "",
            modifiercost: "",
            modifiersale: "",
            images: null,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getModifier(id);
        this.props.modGroupsList();
        document.title = "Modifiers"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            modGroupJson: nextProps.modData.mod_groups_json && JSON.parse(nextProps.modData.mod_groups_json),
            erpid: nextProps.modData.erp_id,
            poscode: nextProps.modData.pos_code,
            priority:nextProps.modData.priority,
            modifiername: nextProps.modData.modifier_name,
            modifiertype: nextProps.modData.modifier_type,
            modifiercost: nextProps.modData.modifier_cost_price,
            modifiersale: nextProps.modData.modifier_sale_price,
        })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    fileSelectedHandler = (e: any) => {
        if (e.target.files.length > 0) {
            this.setState({ images: e.target.files })
        }
    }
    isModifierReady = () => {
        const { modifiername, modifiercost, modGroupJson, modifiersale } = this.state;
        return (modifiername !== "" && (modGroupJson && modGroupJson.length > 0) && modifiercost !== "" && modifiersale !== "");
    }
    handleModGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ modGroupJson: e });
        }
    };
    handleSubmit = (event: any) => {
        let { modifiername, erpid, poscode,priority, modifiertype, modifiercost, modGroupJson, modifiersale } = this.state;
        let id = this.props.match.params.id;
        const data: any = new FormData()
        if (this.state.images) {
            for (var x = 0; x < this.state.images.length; x++) {
                data.append('files', this.state.images[x])
            }
        }
        data.append('modifier_name', modifiername)
        if (erpid !== null && erpid !== "") {
            data.append('erp_id', erpid)
        } else {
            data.append('erp_id', null)
        }
        if (poscode !== null && poscode !== "") {
            data.append('pos_code', poscode)
        } else {
            data.append('pos_code', null)
        }
        data.append('modifier_type', modifiertype)
        data.append('modifier_cost_price', modifiercost)
        data.append('modifier_sale_price', modifiersale)
        data.append('priority', priority)
        data.append('mod_groups_json', JSON.stringify(modGroupJson))
        this.props.editModifier(id, data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { modGroupJson } = this.state;
        console.log("modGroupJson",modGroupJson);
        const { isUpdated, message, modData, modgroups } = this.props;
        console.log("modgroups",modgroups);
        if (isUpdated) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                {/* <CheckChanges path="/edit-modifier" /> */}
                {/* <Topbar /> */}
                <div className="page-content">
                    {/* <Sidebar /> */}
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Modifier Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/modifiers" className="text-primary">Modifiers</Link></li>
                                <li className="breadcrumb-item active">Edit Modifier</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Name <span className="text-danger">*</span></label>
                                                            <input id="modifiername" type="text" name="modifiername" defaultValue={modData.modifier_name} required data-msg="Please enter Modifier Name" className="form-control" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP ID</label>
                                                            <input id="erpid" type="text" name="erpid" defaultValue={modData.erp_id} className="form-control" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Code</label>
                                                            <input id="poscode" type="text" name="poscode" defaultValue={modData.pos_code} className="form-control" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-8 col-sm-8 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Group <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="groups"
                                                                value={modGroupJson}
                                                                options={modgroups}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleModGroupsInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority</label>
                                                            <input id="priority" type="text" name="priority" defaultValue={modData.priority} required data-msg="Please enter Priority" className="form-control" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Type</label>
                                                            <input id="modifiertype" type="text" name="modifiertype" defaultValue={modData.modifier_type} className="form-control" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Modifier Image</label>
                                                            <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                            <small className="form-text">You can also choose multiple images.</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Cost Price <span className="text-danger">*</span></label>
                                                            <input id="modifiercost" type="number" name="modifiercost" defaultValue={modData.modifier_cost_price} required data-msg="Please enter Cost Price" className="form-control" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Sale Price <span className="text-danger">*</span></label>
                                                            <input id="modifiersale" type="number" name="modifiersale" defaultValue={modData.modifier_sale_price} required data-msg="Please enter Sale Price" className="form-control" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button onClick={this.handleSubmit} disabled={!this.isModifierReady()} className="btn btn-primary">Update Modifier</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <Footer /> */}
                    </div>

                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        modData: state.menu.modData,
        modgroups: state.menu.modgroups,
        message: state.menu.message,
        isUpdated: state.menu.isUpdated
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editModifier: function (id: any, data: any) {
            dispatch(editModifier(id, data));
        },
        getModifier: function (id: number) {
            dispatch(getModifier(id));
        },
        modGroupsList: function () {
            dispatch(modGroupsList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMod);