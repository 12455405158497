import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import Dropdownblog2 from './Dropdownblog2';
import Api from '../../../../components/Api';
import moment from 'moment';

const DatatablePstatus = () => {
  const [tableData, setTableData] : any = useState(null);

  useEffect(() => {
    let obj :any = {};
    obj.days = 0;
    let token = sessionStorage.getItem('token');

    Api.post('/admin/orders', obj, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
      .then((response) => {
        if (response.data.success) {
			response.data.successResponse.forEach((element:any) => {
				element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
			})
			// Assuming response.data.orders contains the array of orders from the API response
          const orders = response.data.successResponse;
		console.log("orders",orders)
          // Map the orders array to match the format of rows in the data object
          const rows = orders.map((order:any) => ({
            order_id: order.order_id,
            date_created: order.date_created,
            customer: order.customer.first_name + " " + order.customer.last_name,
            channel: order.order_channel.charAt(0).toUpperCase() + order.order_channel.slice(1),
			order_mode: order.order_mode.charAt(0).toUpperCase() + order.order_mode.slice(1),
			quantity:order.quantity,
			amount: order.order_grossprice.toFixed(0),
			tax : Number(order.tax_amount).toFixed(0),
            status: order.order_status_code == 1 ?
			(
              <Link to={""} className="btn bgl-warning text-warning btn-sm">
                PENDING
              </Link>
            ) 
			:
			""
			,
            dropdown: <Dropdownblog2 />
          }));
		  const columns: any = [
            { label: 'Order ID', field: 'order_id', sort: 'asc', width: 100 },
            { label: 'date', field: 'date_created', sort: 'asc' },
            { label: 'Customer Name', field: 'customer', sort: 'asc' },
            { label: 'Channel', field: 'channel', sort: 'asc' },
            { label: 'Order Mode', field: 'order_mode', sort: 'asc' },
            { label: 'Quantity', field: 'quantity', sort: 'asc' },
            { label: 'Amount', field: 'amount', sort: 'asc' },
            { label: 'Tax', field: 'tax', sort: 'asc' },
            { label: 'Status', field: 'status', sort: 'asc' },
            // { label: 'Action', field: 'dropdown', sort: 'asc' }
          ];
          // Update the table data state with the new columns and rows
          setTableData({
            columns: columns,
            rows: rows
          });
        }
      });
  }, []);

  if (!tableData) {
    // You can return a loading state here if needed
    return <div>Loading...</div>;
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <div className="display mb-4 dataTablesCard no-footer">
              <MDBDataTable striped small data={tableData} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DatatablePstatus;
