import React, { Component, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import jwt from "jsonwebtoken";
import { connect } from "react-redux";
import { secretKey } from "../../../secret";
import { logoutUser, modGroupList } from "../../../redux";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { Button, Modal, Container } from "react-bootstrap";
import "react-toggle/style.css";
import Api from "../../../components/Api";
import { ChoicesProps } from "../../interface/choice";
import AddModGroup from "../new-menu/modifiers/add-mod-group";
import EditModGroup from "../new-menu/modifiers/edit-mod-group";
import { deleteStampById, stampList } from "../../../redux/actions/menuAction";
import { StampsProps } from "../../interface/stamp";
import AddStamp from "./modifiers/add-stamp";
import EditStamp from "./modifiers/edit-stamp";
class Stamps extends Component<
  StampsProps,
  {
    stampModal: any;
    id: any;
    stampData: any;
    stamps: any;
    selectedKey: any;
    basicModal: any;
  }
> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      stampModal: "",
      id: "",
      stampData: {},
      stamps: [],
      selectedKey: "",
      basicModal: false,
    };
  }
  componentDidMount() {
    this.props.stampList();
    document.title = "Stamps";
  }
  shouldComponentUpdate(nextProps: any) {
    return true;
  }

  setStampModal = (condition: any) => {
    this.setState({ stampModal: condition });
  };
  handleLinkSelect = (selectedKey: any, stamp_id?: any) => {
    this.setState({ selectedKey: selectedKey });
    if (stamp_id) {
      this.setState({ id: stamp_id });
    }
  };
  componentDidUpdate(prevProps: any) {
    if (this.props.data.length > prevProps.data.length) {
      this.setState({ stamps: this.props.data });
    }
  }
  setEditStampId = (id: any) => {
    console.log("id", id);
    this.setState({ id: id });
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/stamp/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          this.setState({ stampData: response.data.successResponse });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
  getItemId = () => {
    return this.state.id;
  };
  setModal = (condition: boolean) => {
    this.setState({ basicModal: condition });
  };
  handleDelete = (id: any) => {
    this.setModal(false);
    this.props.deleteStampById(id);
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    return (
      <Fragment>
        {/* Add Category Modal */}

        <AddStamp
          setStampModal={async (e) => this.setStampModal(e)}
          stampModal={this.state.stampModal}
          onModalClose={undefined}
          onSave={undefined}
          columns={undefined}
          validateState={undefined}
          ignoreEditable={undefined}
        />
        <Row>
          <Col xl={12}>
            <Card>
              <Card.Header>
                <Card.Title>Stamps</Card.Title>
                <Button
                  variant="primary"
                  className="p-2"
                  onClick={() => this.setStampModal(true)}
                  // style={{ marginLeft: "55rem" }}
                >
                  Add Stamp
                </Button>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Tab.Container
                    defaultActiveKey={
                      this.props.data &&
                      this.props.data.length > 0 &&
                      this.props.data[0].stamp_description.toLowerCase()
                    }
                  >
                    <Col xl={3}>
                      <Nav
                        as="ul"
                        onSelect={(e) => this.handleLinkSelect(e)}
                        className="flex-column nav-pills mb-3"
                      >
                        {this.props.data.map((data: any, i: any) => (
                          <Nav.Item
                            as="li"
                            key={i}
                            onClick={() => this.setEditStampId(data.stamp_id)}
                            onSelect={(e: any) =>
                              this.handleLinkSelect(e, data.stamp_id)
                            }
                          >
                            <Nav.Link
                              eventKey={data.stamp_description.toLowerCase()}
                            >
                              {data.stamp_title}
                              <span className="iconA">
                                {this.state.selectedKey ==
                                  data.stamp_description.toLowerCase() && (
                                  <i
                                    onClick={() => this.setModal(true)}
                                    style={{
                                      fontSize: "22px",
                                      position: "relative",
                                      top: "3px",
                                    }}
                                    className="ml-4 fa fa-trash"
                                  ></i>
                                )}
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </Col>
                    <Col xl={9}>
                      <Tab.Content className="ml-2">
                        <EditStamp
                          stampData={this.state.stampData}
                          match={undefined}
                          id={this.state.id}
                        />
                      </Tab.Content>{" "}
                    </Col>
                  </Tab.Container>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal show={this.state.basicModal} className="fade">
          <Modal.Header>
            Delete Stamp
            <Button
              variant=""
              className="close"
              onClick={() => this.setModal(false)}
            >
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure,you want to delete this stamp?</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-danger"
              onClick={() => this.setModal(false)}
            >
              Close
            </button>
            <button
              onClick={() => this.handleDelete(this.state.id)}
              className="btn btn-primary"
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    data: state.menu.stamps,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    stampList: function () {
      dispatch(stampList());
    },
    deleteStampById: function (id: any) {
      dispatch(deleteStampById(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Stamps);
