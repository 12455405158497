import React from "react";
import { Pie } from "react-chartjs-2";

const SalesChartPie = (props) => {
  if (!Array.isArray(props.salesData) || props.salesData.length === 0) {
    return <div>{`There are ${0} Sales.`}</div>;
  }

  const orderModeSales = {};
  let totalSales = 0;

  props.salesData.forEach((item) => {
    const orderMode = item.order_mode;
    const sales = item.sales;

    if (!orderModeSales[orderMode]) {
      orderModeSales[orderMode] = sales;
    } else {
      orderModeSales[orderMode] += sales;
    }

    totalSales += sales;
  });

  const labels = [];
  const salesPercentages = [];

  Object.keys(orderModeSales).forEach((orderMode) => {
    const percentage = ((orderModeSales[orderMode] / totalSales) * 100).toFixed(
      0
    );
    labels.push(`${orderMode} (${percentage}%)`);
    salesPercentages.push(percentage);
  });
  const data = {
    datasets: [
      {
        data: salesPercentages,
        borderWidth: 2,
        hoverOffset: 4,
        backgroundColor: [
          `${props.color1 ? props.color1 : "rgba(221, 47, 110, .5)"}`,
          `${props.color2 ? props.color2 : "rgba(221, 47, 110, .7)"}`,
          `${props.color3 ? props.color3 : "rgba(221, 47, 110, .9)"}`,
        ],
        //   hoverBackgroundColor: [
        //     `${props.color1 ? props.color1 : "rgba(221, 47, 110, .199)"}`,
        //     `${props.colo2 ? props.colo2 : "rgba(221, 47, 110, .177)"}`,
        //     `${color3 ? props.color3 : "rgba(221, 47, 110, .155)"}`,
        //   ],
      },
    ],
    labels: props.salesData.map((item) => item.order_mode),
  };

  const options = {
    responsive: true,
    legend: false,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.labels[tooltipItem.index] || "";
          if (label) {
            const value = data.datasets[0].data[tooltipItem.index] || 0;
            return `${label}: ${value}%`;
          }
          return "";
        },
      },
    },
  };

  return (
    <>
      <Pie data={data} height={props.height ? props.height : 330} options={options} />
    </>
  );
};

export default SalesChartPie;
