import { SUPPLIERS_LIST } from "../actions/supplierType"

const initialState: any = {
    data: [] || ""
}
const supplierReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SUPPLIERS_LIST: return {
            ...state,
            data: action.payload
        }
        default: return state;
    }
}
export default supplierReducer;