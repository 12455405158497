import React, { Component } from 'react';
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import { AddGroupProps, AddGroupState } from '../../../interface/menu';
import { secretKey } from '../../../../secret';
import { addGroup, logoutUser, menusList, storesListForMultiSelect } from '../../../../redux';
import { OrderingModes } from '../../../../redux/actions/menuAction';
class AddGroup extends Component<AddGroupProps, AddGroupState> {
    orderModes: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.orderModes = [
            { value: 'online', label: 'Online' },
            { value: 'mobile', label: 'Mobile' },
            { value: 'callcenter', label: 'Call Center' }
        ];
        this.state = {
            groupname: "",
            menu: "",
            image: "",
            priority: "",
            mode: [],
            poscode: null,
            erpid: null,
            is_lsm: '0',
            storesJson: [],
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.menusList();
        this.props.storesList();
        this.props.OrderingModes();
        document.title = "Groups"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    fileSelectedHandler = (e: any) => {
        this.setState({ image: e.target.files })
    }
    isGroupReady = () => {
        const { groupname, menu, image, mode, is_lsm, storesJson } = this.state
        if (is_lsm == "0") {
            return (groupname !== "" && mode.length > 0 && menu !== "" && image !== "");
        } else if (is_lsm == "1") {
            return (groupname !== "" && mode.length > 0 && menu !== "" && image !== "" && storesJson.length > 0);
        }
    }
    handleSaveBtnClick = () => {
        let { groupname, menu, image, mode, priority, poscode, erpid, is_lsm, storesJson } = this.state;
        const data: any = new FormData();
        data.append('file', image[0])
        data.append('group_name', groupname)
        data.append('menu_id', menu)
        data.append('mode', JSON.stringify(mode))
        if (poscode !== null) {
            data.append('pos_code', poscode)
        }
        if (erpid !== null) {
            data.append('erp_id', erpid)
        }
        if (is_lsm == '0') {
            data.append('is_lsm', 0)
        } else if (is_lsm == '1') {
            data.append('is_lsm', 1)
            data.append('stores_json', JSON.stringify(storesJson))
        }
        data.append('priority', priority)
        this.props.addGroup(data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message, menus } = this.props;
        const { is_lsm, storesJson } = this.state;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                {/* <CheckChanges path="/add-group" /> */}
                {/* <Topbar /> */}
                <div className="page-content">
                    {/* <Sidebar /> */}
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Groups Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/groups" className="text-primary">Groups</Link></li>
                                <li className="breadcrumb-item active">Add Group</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Group name <span className="text-danger">*</span></label>
                                                            <input id="groupname" type="text" name="groupname" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Menu Name" className="form-control" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Code</label>
                                                            <input id="poscode" type="text" name="poscode" onKeyDown={this.blockInvalidChar} className="form-control" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP Id</label>
                                                            <input id="erpid" type="text" name="erpid" onKeyDown={this.blockInvalidChar} className="form-control" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Menu <span className="text-danger">*</span></label>
                                                            <select name="menu" className="form-control text-capitalize mt-2" required data-msg="Please select Menu" onChange={this.handleInputChange}>
                                                                <option>Select Menu</option>
                                                                {menus &&
                                                                    menus.map((menu, index) => (
                                                                        <option key={index} value={menu.menu_id}>{menu.menu_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Group Image <span className="text-danger">*</span></label>
                                                            <input id="file" type="file" name="file" className="form-control-file" onChange={this.fileSelectedHandler} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority</label>
                                                            <input id="priority" type="number" name="priority" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Priority" className="form-control" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                options={this.props.orderingModes}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isGroupReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <Footer /> */}
                    </div>
                </div>
            </div >

        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        stores: state.menu.storesoptions,
        message: state.menu.message,
        isInserted: state.menu.isInserted,
        orderingModes: state.menu.orderModes
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        addGroup: function (data: any) {
            dispatch(addGroup(data));
        },
        menusList: function () {
            dispatch(menusList())
        },
        storesList: () => {
            dispatch(storesListForMultiSelect())
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
        OrderingModes: () => {
            dispatch(OrderingModes())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);