import { LINE_CHART,BAR_CHART } from "../actions/analyticsType";

const initialState: any = {
  lineChartData: [],
  barChartData: [],
};

const analyticsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LINE_CHART:
    return {
        ...state,
        lineChartData: action.payload,
      };
    case BAR_CHART:
    console.log(action.payload)
    return {
        ...state,
        barChartData: action.payload,
      };

    default:
      return state;
  }
};
export default analyticsReducer;
