import React, { Component, Fragment} from 'react';
import { Link, Redirect } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import jwt from 'jsonwebtoken'
import { connect } from 'react-redux'
import { UserProps } from '../../../interface/user';
import { secretKey } from '../../../../secret';
import { blockunblockuser, logoutUser, usersList } from '../../../../redux';
import PageTitle from '../../../layouts/PageTitle';
import { Badge, Card, Col, Dropdown, Modal, Row, Table } from 'react-bootstrap';

class ActionFormatter extends Component<{ row: any },{openModal:any}> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            openModal : false,
        }
    }
    setModalOpen = () => {
        this.setState({openModal : true})
    }
    setModalClose = () => {
        this.setState({openModal : false})
    }
    handleDelete = (id: any) => {
        this.props.data.delUser(id,this.props.row.email_address)
    };
    handleBlockUnblock = (id: any, status: any) => {
        this.props.data.blockunblockuser(id, status,this.props.row.email_address);
        this.setModalClose()
    };
    render() { 
        const { row } = this.props
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        return (
            <div>
                {((row.role_id !== 7 && row.role_id !== 1) && roleId !== 7) &&
                    <>
                        <button title={row.status == 0 ? "UnBlock" : "Block"} onClick={()=>this.setModalOpen()} data-toggle="modal" data-target={`#blockunblock${row.user_id}`} className={row.status == 0 ? "btn btn-outline-danger mr-2" : "btn btn-outline-success mr-2"}><i className={row.status == 0 ? "fa fa-lock" : "fa fa-unlock"}></i></button>
                        {/* <button title="Delete User" className="btn btn-outline-danger" data-toggle="modal" data-target={`#DelUser${row.user_id}`}><i className="fa fa-trash"></i></button> */}
                        <Link title="Edit User" className="btn btn-outline-primary ml-2" to={`/edit-user/${row.user_id}`}><i className="fa fa-edit"></i></Link>
                    </>
                }
                {roleId == 7 &&
                    <>
                        <button title={row.status == 0 ? "UnBlock" : "Block"} onClick={()=>this.setModalOpen()} data-toggle="modal" data-target={`#blockunblock${row.user_id}`} className={row.status == 0 ? "btn btn-outline-success mr-2" : "btn btn-outline-danger mr-2"}><i className={row.status == 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                        {/* <button title="Delete User" className="btn btn-outline-danger" data-toggle="modal" data-target={`#DelUser${row.user_id}`}><i className="fa fa-trash"></i></button> */}
                        <Link title="Edit User" className="btn btn-outline-primary ml-2" to={`/edit-user/${row.user_id}`}><i className="fa fa-edit"></i></Link>
                    </>
                }
                {/* <!-- Modal--> */}
                <div id={`DelUser${row.user_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete User</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to delete this user?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button onClick={() => this.handleDelete(row.user_id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Block/Unblock Modal--> */}
                {/* <div id={`blockunblock${row.user_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left"> */}
                <Modal className="modal fade"  show={this.state.openModal}  onHide={this.state.openModal} >
                    {/* <div role="document" className="modal-dialog"> */}
                        {/* <div className="modal-content"> */}
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.status == 0 ? "UnBlock" : "Block"} User</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" onClick={()=>this.setModalClose()} className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.status == 0 ? "UnBlock" : "Block"} this user?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" onClick={()=>this.setModalClose()} className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.user_id, row.status)} className="btn btn-primary">Confirm</button>
                            </div>
                        {/* </div> */}
                    {/* </div> */}
                </Modal>
                {/* </div> */}
            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}
class Users extends Component<UserProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
	ismounted = false;
    componentDidMount = () => {
		this.ismounted = true;
		if(this.ismounted)
		{
			this.props.usersList();
		}
    }
	componentWillUnmount = () => {
		this.ismounted = false
	}
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal:  () => { return <Redirect to="/add-user" /> },
            noDataText: 'Users Not Found'
        };
        return (
            <div className="row">
			   <div className="col-xl-12">
				  <div className="card">
					 <div className="card-body">
						<div className="row">
						   <div className="col-md-12 order-md-1">
						<BootstrapTable multiColumnSearch={true} bordered={false} version='4' data={this.props.users} search={true} pagination={this.props.users.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='users.csv' condensed={true}>
							<TableHeaderColumn  width='100' thStyle={{ fontSize: 15, whiteSpace: 'normal',fontWeight:"bold" }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='user_id' csvHeader='#'  dataSort={true} isKey></TableHeaderColumn>
							<TableHeaderColumn  width='160' thStyle={{ fontSize: 15, whiteSpace: 'normal',fontWeight:"bold" }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='user_name' csvHeader='Name'  columnTitle>Name</TableHeaderColumn>
							<TableHeaderColumn width='240'thStyle={{ fontSize: 15, whiteSpace: 'normal',fontWeight:"bold",marginLeft:"-10px" }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='email_address' csvHeader='Email'  columnTitle>Email</TableHeaderColumn>
							{/* <TableHeaderColumn  dataField='city' csvHeader='City' width='100' columnTitle>City</TableHeaderColumn> */}
							{/* <TableHeaderColumn width='150' thStyle={{ fontSize: 15, whiteSpace: 'normal',fontWeight:"bold" }} dataField='country_name' csvHeader='Country'  columnTitle>Country</TableHeaderColumn> */}
							{/* <TableHeaderColumn  dataField='address' csvHeader='Address' width='200' columnTitle>Address</TableHeaderColumn> */}
							<TableHeaderColumn width='180' thStyle={{ fontSize: 15, whiteSpace: 'normal',fontWeight:"bold" }} dataField='group_name' csvHeader='Group'  columnTitle>Group</TableHeaderColumn>
							<TableHeaderColumn width='100' thStyle={{ fontSize: 15, whiteSpace: 'normal',fontWeight:"bold" }} dataField='role_name' csvHeader='Role'  columnTitle>Role</TableHeaderColumn>
							<TableHeaderColumn width='200' thStyle={{ fontSize: 15, whiteSpace: 'normal',fontWeight:"bold" }} dataField='action'  dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
						</BootstrapTable>
						
						</div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
					
					
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        users: state.setting.users
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        usersList: function () {
            dispatch(usersList())
        },
        // delUser: function (id: any,email_address:any) {
        //     dispatch(delUser(id,email_address))
        // },
        blockunblockuser: function (id: any, status: any,email_address:any) {
            dispatch(blockunblockuser(id, status,email_address))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Users);